// External
import { useState, useCallback } from 'react'
import { View, TouchableOpacity, type ViewStyle, FlatList } from 'react-native'
import { TextInput, Menu, List } from 'react-native-paper'
// Components
import { Text } from './Text'
// Constants
import { colors } from '@/constants'
// Models
import { type Option } from '@/models'

interface Props {
  options: Option[]
  placeholder?: string
  defaultValue?: Option
  textLabel?: string
  style?: ViewStyle
  inputStyle?: ViewStyle
  onSelect: (selectedOption: Option) => void
}

export const DropdownSelect = ({
  options,
  placeholder,
  defaultValue,
  textLabel,
  style,
  inputStyle,
  onSelect
}: Props) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [selectedOption, setSelectedOption] = useState(
    defaultValue !== undefined ? defaultValue : options[0]
  )
  const [menuWidth, setMenuWidth] = useState<number>(0)

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen)
  }

  const handleOptionSelect = (option: Option) => {
    setSelectedOption(option)
    onSelect(option)
    toggleDropdown()
  }

  const renderItem = useCallback(({ item }: { item: Option }) => {
    return (
      // Menu.Item has fixed width and won't take 100% of the parent width but List.Item will
      <List.Item
        key={item.label}
        onPress={() => {
          handleOptionSelect(item)
          setIsDropdownOpen(false)
        }}
        title={item.label}
        style={{
          backgroundColor: colors.white,
          borderColor: colors.lightGray,
          borderWidth: 1,
          width: '100%'
        }}
        titleStyle={{ fontFamily: 'Maersk-Regular' }}
      />
    )
  }, [])

  return (
    <View
      style={style}
      onLayout={({ nativeEvent }) => {
        setMenuWidth(nativeEvent.layout.width)
      }}
    >
      <Text variant="baseBold">{textLabel}</Text>

      <Menu
        style={{ width: menuWidth }}
        visible={isDropdownOpen}
        onDismiss={toggleDropdown}
        anchor={
          <TouchableOpacity onPress={toggleDropdown}>
            <View pointerEvents="none">
              <TextInput
                value={selectedOption.label}
                placeholder={placeholder}
                placeholderTextColor={colors.mediumGray}
                right={
                  <TextInput.Icon
                    iconColor={colors.orange}
                    icon={isDropdownOpen ? 'menu-up' : 'menu-down'}
                  />
                }
                editable={false}
                dense
                mode="outlined"
                theme={{
                  roundness: 4
                }}
                contentStyle={{ fontFamily: 'Maersk-Regular' }}
                outlineColor={colors.lightGray}
                style={inputStyle}
              />
            </View>
          </TouchableOpacity>
        }
        anchorPosition="bottom"
        contentStyle={{ paddingVertical: 0 }}
      >
        <FlatList
          keyboardShouldPersistTaps="handled"
          style={{ maxHeight: 224 }}
          initialNumToRender={4}
          maxToRenderPerBatch={8}
          bounces={false}
          data={options}
          renderItem={renderItem}
        />
      </Menu>
    </View>
  )
}
