// External
import { type ReactNode } from 'react'
import { View, type ViewStyle } from 'react-native'
import { ActivityIndicator } from 'react-native-paper'
// Components
import { Text } from '@/components/common'

interface Props {
  loading: boolean
  message?: string
  children: ReactNode
  style?: ViewStyle
}

export const LoadingContent = ({
  loading,
  message,
  children,
  style
}: Props) => {
  return (
    <>
      {loading ? (
        <View
          style={[
            {
              marginTop: 'auto',
              marginBottom: 'auto',
              alignItems: 'center'
            },
            style
          ]}
        >
          <ActivityIndicator size={64} />

          {message !== null && <Text style={{ marginTop: 24 }}>{message}</Text>}
        </View>
      ) : (
        children
      )}
    </>
  )
}
