// Components
import { Text } from './Text'
// React Native
import { View, type StyleProp, type ViewStyle, Platform } from 'react-native'
// React Native Paper
import {
  TextInput,
  type TextInputProps,
  HelperText,
  useTheme
} from 'react-native-paper'
// Formik
import type { FormikErrors, FormikTouched } from 'formik'
import { colors } from '@/constants'
import { useState } from 'react'

interface CustomInputProps<T> extends TextInputProps {
  name: keyof T
  style?: StyleProp<ViewStyle>
  textLabel?: string
  errors?: FormikErrors<T>
  touched?: FormikTouched<T>
}

export const Input = <FormSchemaType,>({
  name,
  style,
  textLabel,
  label,
  placeholder,
  onChangeText,
  onBlur,
  value,
  maxLength,
  secureTextEntry,
  inputMode,
  autoComplete,
  autoCapitalize,
  touched,
  errors
}: CustomInputProps<FormSchemaType>) => {
  const [isPressed, setIsPressed] = useState(false)
  const { colors: themeColors } = useTheme()

  const hasErrors =
    !isPressed &&
    touched?.[name] !== undefined &&
    touched[name] === true &&
    errors?.[name] !== undefined

  const getHelperText = () => {
    if (
      errors?.[name] !== undefined &&
      typeof errors[name] === 'string' &&
      touched?.[name] === true
    ) {
      return errors[name] as string
    }

    // Space prevents layout shift
    return ' '
  }

  const getActualTextColor = () => {
    if (hasErrors) {
      return themeColors.error
    }

    if (isPressed) {
      return themeColors.primary
    }
  }

  return (
    <View style={style}>
      <Text variant="baseBold" color={getActualTextColor()}>
        {textLabel}
      </Text>

      <TextInput
        dense
        mode="outlined"
        label={label}
        placeholder={placeholder}
        onChangeText={onChangeText}
        onBlur={(e) => {
          if (onBlur !== undefined) {
            onBlur(e)

            if (Platform.OS === 'web') {
              setIsPressed(false)
            }
          }
        }}
        value={value}
        maxLength={maxLength}
        secureTextEntry={secureTextEntry}
        inputMode={inputMode}
        autoComplete={autoComplete}
        autoCapitalize={autoCapitalize}
        placeholderTextColor={colors.mediumGray}
        outlineColor={hasErrors ? themeColors.error : colors.lightGray}
        theme={{
          roundness: 4
        }}
        onFocus={() => {
          setIsPressed(true)
        }}
        onEndEditing={() => {
          setIsPressed(false)
        }}
        contentStyle={{ fontFamily: 'Maersk-Regular' }}
      />

      <HelperText type="error" visible={hasErrors} padding="none">
        {getHelperText()}
      </HelperText>
    </View>
  )
}
