// External
import { View } from 'react-native'
// Components
import { Text } from '@/components/common'
// Constants
import { colors } from '@/constants'

export const RequestRestrictions = () => {
  return (
    <View>
      <Text
        style={{ textAlign: 'center', color: colors.orange, marginBottom: 32 }}
        variant="mediumBold"
      >
        Request Restrictions
      </Text>

      <Text style={{ marginBottom: 24 }}>
        • Requests only available from the current day to 7 days ahead
      </Text>

      <Text style={{ marginBottom: 24 }}>
        • After 11AM (New Jersey time) requests are disabled for the next day
      </Text>

      <Text>• Requests from Sunday to Monday are not allowed</Text>
    </View>
  )
}
