// External
import { collection, doc, getDocs, query, writeBatch } from 'firebase/firestore'
// Config
import { db } from '@/config/firebase'
// Models
import {
  type Notification,
  type NotificationUpdate
} from '@/models/notification.interface'

export const getNotifications = async (userId: string) => {
  try {
    const userRef = doc(collection(db, 'users'), userId)
    const notificationsRef = collection(userRef, 'notifications')

    const notificationsQuery = query(notificationsRef)

    const notificationsSnapshot = await getDocs(notificationsQuery)

    const notifications = notificationsSnapshot.docs.map((doc) => {
      const notificationData = doc.data() as Notification

      return {
        id: doc.id,
        ...notificationData
      }
    })

    return notifications
  } catch (error) {
    console.error('Error getting notifications:', error)
    return await Promise.reject(error)
  }
}

export const updateNotifications = async ({
  userId,
  notificationIds,
  fields
}: {
  userId: string
  notificationIds: string[]
  fields: NotificationUpdate
}) => {
  try {
    const batch = writeBatch(db)
    const userRef = doc(collection(db, 'users'), userId)

    for (const notificationId of notificationIds) {
      const notificationRef = doc(userRef, 'notifications', notificationId)
      batch.update(notificationRef, fields)
    }

    await batch.commit()
  } catch (error) {
    console.error('Error updating notifications:', error)
    return await Promise.reject(error)
  }
}
