// External
import { View } from 'react-native'
import Toast from 'react-native-toast-message'
import { Formik } from 'formik'
import { object, string, type InferType, ref } from 'yup'
// Components
import { Button, Input, Text } from '@/components/common'
// Models
import { UseCaseError } from '@/models/use-case-error.class'
// Use cases
import { editPassword } from '@/useCases/user.use-case'
//  Hooks
import usePortalStore from '@/store/usePortalStore'

export const EditPassword = () => {
  const hideModal = usePortalStore((state) => state.hideModal)

  const editPasswordSchema = object({
    password: string()
      .min(8, 'New password is too short')
      .required('Please enter a new password'),
    repeatPassword: string()
      .oneOf([ref('password')], 'Passwords must match')
      .required('Please re enter your new password')
  })

  type EditPasswordType = InferType<typeof editPasswordSchema>

  return (
    <Formik
      initialValues={{ password: '', repeatPassword: '' }}
      validationSchema={editPasswordSchema}
      onSubmit={async ({ password }) => {
        try {
          const res = await editPassword(password)
          Toast.show({ type: 'success', text1: res.title })
          hideModal()
        } catch (error) {
          if (error instanceof UseCaseError) {
            Toast.show({
              type: 'error',
              text1: error.title,
              text2: error.message
            })
          }
        }
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
        touched,
        isSubmitting,
        isValid
      }) => (
        <View>
          <Text variant="h2Bold" style={{ marginBottom: 32 }}>
            EDIT YOUR PASSWORD
          </Text>

          <Input<EditPasswordType>
            name="password"
            textLabel="New password (min. 8 characters)"
            onChangeText={handleChange('password')}
            onBlur={handleBlur('password')}
            value={values.password}
            autoCapitalize="none"
            secureTextEntry
            touched={touched}
            errors={errors}
          />

          <Input<EditPasswordType>
            name="repeatPassword"
            textLabel="Repeat the new password"
            onChangeText={handleChange('repeatPassword')}
            onBlur={handleBlur('repeatPassword')}
            value={values.repeatPassword}
            autoCapitalize="none"
            secureTextEntry
            touched={touched}
            errors={errors}
          />

          <Button
            style={{ marginTop: 40 }}
            disabled={!isValid || isSubmitting}
            loading={isSubmitting}
            onPress={() => {
              handleSubmit()
            }}
          >
            {isSubmitting ? 'Saving changes' : 'Save changes'}
          </Button>
        </View>
      )}
    </Formik>
  )
}
