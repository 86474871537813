// External
import * as Linking from 'expo-linking'
import { type LinkingOptions } from '@react-navigation/native'
// Models
import { type HomeTabsParamList } from '@/navigators/HomeTabs'

export const linking: LinkingOptions<HomeTabsParamList> = {
  prefixes: [
    Linking.createURL('/'),
    'https://app.apmlaboravailability.com',
    'https://app-dev.apmlaboravailability.com'
  ],
  config: {
    screens: {
      HistoryStack: {
        screens: {
          History: 'history/:id?'
        }
      },
      Notifications: 'notifications',
      Profile: 'profile'
    }
  }
}
