// External
import { View } from 'react-native'
import * as Sentry from '@sentry/react-native'
import * as Updates from 'expo-updates'
// Components
import { Button } from './Button'
import { Text } from './Text'

export const EASUpdate = () => {
  return (
    <View>
      <Text style={{ marginBottom: 34 }}>
        An update for your installed version of APM Labor Availability is
        available
      </Text>

      <Button
        onPress={async () => {
          try {
            await Updates.fetchUpdateAsync()
            await Updates.reloadAsync()
          } catch (error) {
            Sentry.captureException(error)
          }
        }}
      >
        Update and restart
      </Button>
    </View>
  )
}
