// External
import { Timestamp } from 'firebase/firestore'
import type { CameraCapturedPicture } from 'expo-camera'
import { differenceInDays, addDays } from 'date-fns'
import type { DocumentPickerAsset } from 'expo-document-picker'
import type { ImagePickerAsset } from 'expo-image-picker'
// Models
import { UseCaseError, type User, type RequestStatus } from '@/models'
// Services
import {
  createRequest,
  getConflictingRequests,
  updateRequest,
  updateRequests
} from '@/services/request.service'
import { uploadFiles } from '@/services/storage.service'

export const cancelRequest = async (id: string) => {
  try {
    await updateRequest({ id, fields: { status: 'cancelled' } })
    return {
      title: 'Request cancelled successfully'
    }
  } catch (error) {
    return await Promise.reject(new UseCaseError(error))
  }
}

interface SubmitRequestParameters {
  attachments: Array<
    DocumentPickerAsset | ImagePickerAsset | CameraCapturedPicture
  >
  dateFrom: Date
  dateTo: Date
  reason: string
  user: User
  userId: string
}

export const submitRequest = async ({
  attachments,
  dateFrom,
  dateTo,
  reason,
  user,
  userId
}: SubmitRequestParameters) => {
  try {
    const request = {
      createdAt: Timestamp.now(),
      attachments: [],
      dateFrom: Timestamp.fromDate(dateFrom),
      dateTo: Timestamp.fromDate(dateTo),
      reason,
      status: 'pending' as RequestStatus,
      user,
      userId
    }

    const conflictingRequests = await getConflictingRequests(request)
    if (conflictingRequests.length > 0) {
      throw new Error(
        'You already have requests for the selected date range, please review your calendar'
      )
    }

    const totalRequestDays = differenceInDays(dateTo, dateFrom)
    const requests = []

    for (let i = 0; i <= totalRequestDays; i++) {
      const newRequest = {
        ...request,
        dateFrom: Timestamp.fromDate(addDays(dateFrom, i)),
        dateTo: Timestamp.fromDate(addDays(dateFrom, i))
      }
      requests.push(newRequest)
    }

    // Create the request to get the id
    const requestIds = await createRequest(requests)

    // Upload the attachments with a path that includes the user and request ids
    const uploadedAttachments = await uploadFiles({
      files: attachments,
      uploadPath: `users/${userId}/requests/${requestIds[0]}`
    })

    // Update the request with the attachments
    await updateRequests(
      requestIds.map((id) => ({
        id,
        fields: {
          attachments: uploadedAttachments
        }
      }))
    )

    return {
      title: 'Request submitted successfully'
    }
  } catch (error) {
    return await Promise.reject(new UseCaseError(error))
  }
}
