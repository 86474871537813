// External
import { isSameDay, differenceInDays, addDays, format } from 'date-fns'
// Constants
import { colors } from '@/constants'

export const generateMarkedDates = (
  dateFrom: Date | undefined,
  dateTo: Date | undefined
) => {
  if (dateFrom === undefined || dateTo === undefined) {
    return undefined
  }

  const validMarkedDates: Record<
    string,
    {
      color: string
      textColor: string
      selected?: boolean
      marked?: boolean
      startingDay?: boolean
      endingDay?: boolean
    }
  > = {}

  if (isSameDay(dateFrom, dateTo)) {
    return {
      [format(dateFrom, 'yyyy-MM-dd')]: { selected: true }
    }
  }

  const daysBetweenMinAndMaxDate = differenceInDays(dateTo, dateFrom)

  for (let i = 0; i < daysBetweenMinAndMaxDate; i++) {
    const nextDate = addDays(dateFrom, i)
    validMarkedDates[format(nextDate, 'yyyy-MM-dd')] = {
      color: colors.orange,
      textColor: colors.white,
      marked: true
    }
  }

  validMarkedDates[format(dateFrom, 'yyyy-MM-dd')] = {
    color: colors.orange,
    textColor: colors.white,
    startingDay: true
  }

  validMarkedDates[format(dateTo, 'yyyy-MM-dd')] = {
    color: colors.orange,
    textColor: colors.white,
    endingDay: true
  }

  return validMarkedDates
}
