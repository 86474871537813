// External
import { Formik } from 'formik'
import { object, string, type InferType } from 'yup'
import Toast from 'react-native-toast-message'
import { type StackScreenProps } from '@react-navigation/stack'
import { type PreHomeStackParamList } from '@/navigators/PreHomeStack'
import { signInWithEmailAndPassword } from 'firebase/auth'
import Constants from 'expo-constants'
import OneSignal from 'react-native-onesignal'
// Components
import {
  Button,
  Input,
  TextAndLink,
  APMLogo,
  Version
} from '@/components/common'
import { ResetPassword } from '@/components/modal'
// Config
import { auth } from '@/config/firebase'
// Hooks
import useModalStore from '@/store/usePortalStore'
// Layouts
import { KeyboardFix, SafeArea } from '@/layouts'
// Utils
import { parseErrorMessage } from '@/utils'
// Use cases
import { updateAppData } from '@/useCases/user.use-case'

type Props = StackScreenProps<PreHomeStackParamList, 'Login'>

const Login = ({ navigation }: Props) => {
  const showModal = useModalStore((state) => state.showModal)

  const loginSchema = object({
    email: string()
      .email('Please enter a valid email')
      .required('Please enter your email'),
    password: string().required('Please enter your password')
  })

  type LoginSchemaType = InferType<typeof loginSchema>

  return (
    <SafeArea style={{ justifyContent: 'center', marginHorizontal: 24 }}>
      <KeyboardFix>
        <APMLogo style={{ marginBottom: 40, marginTop: 'auto' }} />

        <Formik
          initialValues={{ email: '', password: '' }}
          validationSchema={loginSchema}
          onSubmit={async ({ email, password }) => {
            try {
              const appVersion = Constants.expoConfig?.version ?? ''
              const deviceState = await OneSignal.getDeviceState()
              const isPushEnabled = deviceState?.isPushDisabled ?? false

              await signInWithEmailAndPassword(auth, email, password)

              void updateAppData({
                appVersion,
                isPushEnabled
              })
            } catch (error: any) {
              console.error('Error logging in:', error)
              Toast.show({
                type: 'error',
                text1: 'Something went wrong',
                text2: parseErrorMessage(error.message)
              })
            }
          }}
        >
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            values,
            touched,
            errors,
            isValid,
            isSubmitting
          }) => (
            <>
              <Input<LoginSchemaType>
                name="email"
                textLabel="Email"
                onChangeText={handleChange('email')}
                onBlur={handleBlur('email')}
                value={values.email}
                inputMode="email"
                autoComplete="email"
                autoCapitalize="none"
                touched={touched}
                errors={errors}
              />

              <Input<LoginSchemaType>
                name="password"
                textLabel="Password"
                onChangeText={handleChange('password')}
                onBlur={handleBlur('password')}
                value={values.password}
                autoCapitalize="none"
                secureTextEntry
                touched={touched}
                errors={errors}
              />

              <TextAndLink
                text="Have you forgotten your password?"
                linkOnPress={() => {
                  showModal({ content: <ResetPassword /> })
                }}
                linkText="Reset password"
              />

              <Button
                style={{ marginTop: 80, marginBottom: 40 }}
                onPress={() => {
                  handleSubmit()
                }}
                disabled={!isValid || isSubmitting}
                loading={isSubmitting}
              >
                Log In
              </Button>

              <TextAndLink
                text="You don't have an account?"
                style={{ justifyContent: 'center' }}
                linkOnPress={() => {
                  navigation.navigate('SignupStep1')
                }}
                linkText="Create account"
              />

              <Version style={{ marginTop: 'auto', marginBottom: 16 }} />
            </>
          )}
        </Formik>
      </KeyboardFix>
    </SafeArea>
  )
}
export default Login
