interface Parameters {
  firstName: string
  lastName: string
}

export const getInitials = ({ firstName, lastName }: Parameters) => {
  let initials = ''

  if (firstName.length > 0) {
    initials += firstName[0]
  }

  if (lastName.length > 0) {
    initials += lastName[0]
  }

  return initials
}
