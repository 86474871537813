// External
import { View } from 'react-native'
import { useState } from 'react'
import Toast from 'react-native-toast-message'
// Components
import { Text, Button } from '@/components/common'
// Models
import { UseCaseError } from '@/models/use-case-error.class'
// Repositories
import { cancelRequest } from '@/useCases/request.use-case'

export const CancelRequest = ({
  id,
  onCancel
}: {
  id: string
  onCancel: () => void
}) => {
  const [loading, setLoading] = useState(false)

  return (
    <View>
      <Text style={{ marginBottom: 160 }} variant="baseBold">
        You are about to cancel the time off request. Are you sure you want to
        proceed?
      </Text>

      <Button
        loading={loading}
        disabled={loading}
        onPress={async () => {
          try {
            setLoading(true)
            const res = await cancelRequest(id)
            setLoading(false)
            onCancel()
            Toast.show({
              type: 'success',
              text1: res.title
            })
          } catch (error) {
            setLoading(false)
            if (error instanceof UseCaseError) {
              Toast.show({
                type: 'error',
                text1: error.title,
                text2: error.message
              })
            }
          }
        }}
      >
        {loading ? 'Cancelling the request' : 'Cancel the request'}
      </Button>
    </View>
  )
}
