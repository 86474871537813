import { type ButtonProps } from 'react-native-paper'
import { BaseButton } from './BaseButton'

export const Button = ({
  style,
  onPress,
  disabled,
  loading,
  children,
  icon
}: ButtonProps) => {
  return (
    <BaseButton
      style={style}
      onPress={onPress}
      disabled={disabled}
      loading={loading}
      mode="contained"
      icon={icon}
      uppercase={false}
    >
      {children}
    </BaseButton>
  )
}
