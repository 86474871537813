// External
import { addDays, format, differenceInDays } from 'date-fns'
// Models
import { type Option } from '@/models'
// Utils
import { generateValidCalendarRange } from './generateValidCalendarRange'

export const generateValidDropdownSelectDates: () => Option[] = () => {
  const { minDate, maxDate } = generateValidCalendarRange()

  const validDropdownSelectDates = [minDate]
  const daysBetweenMinAndMaxDate = differenceInDays(maxDate, minDate)

  for (let i = 0; i < daysBetweenMinAndMaxDate; i++) {
    const nextDate = addDays(validDropdownSelectDates[i], 1)
    validDropdownSelectDates.push(nextDate)
  }

  return validDropdownSelectDates.map((date) => ({
    value: date,
    label: format(date, 'MMM do, yyyy')
  }))
}
