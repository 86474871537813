// External
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'
import type { NavigatorScreenParams } from '@react-navigation/native'
// Components
import { TabBarIcon } from '@/components/navigation'
// Constants
import { colors } from '@/constants'
// Navigators
import CalendarStack from './CalendarStack'
import HistoryStack from './HistoryStack'
// Store
import useAppStore from '@/store/useAppStore'
// Views
import Notifications from '@/views/Home/Notifications'
import Profile from '@/views/Home/Profile'
// Models
import { type HistoryStackParamList } from './HistoryStack'

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type HomeTabsParamList = {
  CalendarStack: undefined
  HistoryStack: NavigatorScreenParams<HistoryStackParamList>
  Notifications: undefined
  Profile: undefined
}

const Tab = createBottomTabNavigator<HomeTabsParamList>()

const HomeTabs = () => {
  const unreadNotifications = useAppStore((state) => state.unreadNotifications)

  return (
    <Tab.Navigator
      screenOptions={{
        headerShown: false,
        tabBarShowLabel: false,
        tabBarHideOnKeyboard: true,
        tabBarStyle: {
          backgroundColor: colors.white,
          height: 80,
          borderTopColor: colors.lightGray
        },
        tabBarBadgeStyle: {
          backgroundColor: colors.mediumGray,
          color: colors.white,
          fontFamily: 'Maersk-Bold'
        }
      }}
    >
      <Tab.Screen
        name="CalendarStack"
        component={CalendarStack}
        options={{
          headerTitle: 'Calendar',
          tabBarIcon: ({ focused }) => (
            <TabBarIcon
              focused={focused}
              routeName="CalendarStack"
              label="Calendar"
            />
          )
        }}
      />

      <Tab.Screen
        name="HistoryStack"
        component={HistoryStack}
        options={{
          headerTitle: 'History',
          tabBarIcon: ({ focused }) => (
            <TabBarIcon
              focused={focused}
              routeName="HistoryStack"
              label="History"
            />
          )
        }}
      />

      <Tab.Screen
        name="Notifications"
        component={Notifications}
        options={{
          headerShown: false,
          ...(unreadNotifications > 0 && { tabBarBadge: unreadNotifications }),
          tabBarIcon: ({ focused }) => (
            <TabBarIcon
              focused={focused}
              routeName="Notifications"
              label="Notifications"
            />
          )
        }}
      />

      <Tab.Screen
        name="Profile"
        component={Profile}
        options={{
          headerShown: false,
          tabBarIcon: ({ focused }) => (
            <TabBarIcon focused={focused} routeName="Profile" label="Profile" />
          )
        }}
      />
    </Tab.Navigator>
  )
}
export default HomeTabs
