// External
import { createStackNavigator } from '@react-navigation/stack'
import { Platform } from 'react-native'
// Views
import Welcome from '@/views/PreHome/Welcome'
import NotificationPermissions from '@/views/PreHome/NotificationPermissions'
import Login from '@/views/PreHome/Login'
import SignupStep1 from '@/views/PreHome/SignupStep1'
import SignupStep2 from '@/views/PreHome/SignupStep2'
import SignupStep3 from '@/views/PreHome/SignupStep3'
import AccountVerification from '@/views/PreHome/AccountVerification'
// Hooks
import useAppStore from '@/store/useAppStore'
// Models
import { type PendingUser } from '@/models/pending-user.interface'
// Components
import { NavigationHeader } from '@/components/navigation'

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type PreHomeStackParamList = {
  Welcome: undefined
  NotificationPermissions: undefined
  Login: undefined
  SignupStep1: undefined
  SignupStep2: Omit<
    PendingUser,
    'waterfront' | 'seniority' | 'email' | 'createdAt'
  >
  SignupStep3: Omit<PendingUser, 'createdAt'>
  AccountVerification: undefined
}

const Stack = createStackNavigator<PreHomeStackParamList>()

const PreHomeStack = () => {
  const showWelcomeScreen: boolean = useAppStore(
    (state) => state.showWelcomeScreen
  )
  const showNotificationsScreen: boolean = useAppStore(
    (state) => state.showNotificationsScreen
  )
  const isLoggedIn = useAppStore((state) => state.isLoggedIn)

  return (
    <Stack.Navigator
      screenOptions={{
        headerMode: 'screen',
        header: NavigationHeader
      }}
    >
      {!isLoggedIn ? (
        <Stack.Group>
          {showWelcomeScreen && (
            <Stack.Screen
              name="Welcome"
              component={Welcome}
              options={{ headerShown: false }}
            />
          )}

          {showNotificationsScreen &&
            (Platform.OS === 'android' || Platform.OS === 'ios') && (
              <Stack.Screen
                name="NotificationPermissions"
                component={NotificationPermissions}
                options={{ headerShown: false }}
              />
            )}

          <Stack.Screen
            name="Login"
            component={Login}
            options={{ headerShown: false }}
          />

          <Stack.Screen
            name="SignupStep1"
            component={SignupStep1}
            options={{
              headerTitle: 'CREATE ACCOUNT'
            }}
          />

          <Stack.Screen
            name="SignupStep2"
            component={SignupStep2}
            options={{
              headerTitle: 'CREATE ACCOUNT'
            }}
          />

          <Stack.Screen
            name="SignupStep3"
            component={SignupStep3}
            options={{
              headerTitle: 'CREATE ACCOUNT'
            }}
          />
        </Stack.Group>
      ) : (
        <Stack.Group>
          <Stack.Screen
            name="AccountVerification"
            component={AccountVerification}
          />
        </Stack.Group>
      )}
    </Stack.Navigator>
  )
}
export default PreHomeStack
