// External
import { startOfDay, addDays, getHours, getDay } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'

export const generateValidCalendarRange = () => {
  const njTimezone = 'America/New_York'
  const now = utcToZonedTime(new Date(), njTimezone)
  const hours = getHours(now)
  const day = getDay(now)

  // The first day selectable by default is tomorrow
  let minDate = startOfDay(addDays(now, 1))
  const maxDate = startOfDay(addDays(now, 7))

  // Cannot request the following day off if it's past 11 am or Sunday
  if (hours >= 11 || day === 0) {
    minDate = startOfDay(addDays(now, 2))
  }

  // Saturdays after 11 am cannot request the following Monday off
  if (hours >= 11 && day === 6) {
    minDate = startOfDay(addDays(now, 3))
  }

  return {
    minDate,
    minDateString: minDate.toDateString(),
    maxDate,
    maxDateString: maxDate.toDateString()
  }
}
