import { collection, doc, getDoc } from 'firebase/firestore'
import { db } from '@/config/firebase'

export const getAppVersion = async () => {
  try {
    const versionsRef = doc(collection(db, 'appData'), 'versions')
    const versions = await getDoc(versionsRef)

    const { min, latest } = versions.data()?.android as {
      min: number
      latest: number
    }

    return { min, latest }
  } catch (error) {
    console.error('Error getting app versions:', error)
    return await Promise.reject(error)
  }
}
