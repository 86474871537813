// External
import { createStackNavigator } from '@react-navigation/stack'
// Components
import { NavigationHeader } from '@/components/navigation'
// Views
import Calendar from '@/views/Home/Calendar/Calendar'
import RequestADayOff from '@/views/Home/Calendar/RequestADayOff'

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type CalendarStackParamList = {
  Calendar: undefined
  RequestADayOff: {
    dateFrom: Date
    dateTo: Date
  }
}

const Stack = createStackNavigator<CalendarStackParamList>()

const CalendarStack = () => {
  return (
    <Stack.Navigator
      initialRouteName="Calendar"
      screenOptions={{
        header: NavigationHeader,
        headerTitle: 'REQUEST A DAY OFF'
      }}
    >
      <Stack.Screen name="Calendar" component={Calendar} />

      <Stack.Screen name="RequestADayOff" component={RequestADayOff} />
    </Stack.Navigator>
  )
}
export default CalendarStack
