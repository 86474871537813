import { colors } from '@/constants'
import { type FontSize } from '@/models'
import { DefaultTheme as NavigationDefaultTheme } from '@react-navigation/native'
import deepmerge from 'deepmerge'
import {
  MD3LightTheme,
  adaptNavigationTheme,
  configureFonts
} from 'react-native-paper'
import { type MD3Type } from 'react-native-paper/lib/typescript/src/types'

const { LightTheme } = adaptNavigationTheme({
  reactNavigationLight: NavigationDefaultTheme
})

const CombinedDefaultTheme = deepmerge(MD3LightTheme, LightTheme)

const fontSizes = {
  lg: 38,
  md: 20,
  sm: 14,
  xs: 12
}

// ! fontWeight doesn't seem to work properly on React Native, when fontWeight was normal or bold, the font wouldn't load
// ! and it would show the system's default font instead on Android. Use 400 for regular and 500 for bold
const mediumBoldConfig: Partial<MD3Type> = {
  fontFamily: 'Maersk-Bold',
  fontWeight: '500',
  fontSize: fontSizes.md
}

const fontConfig: Record<FontSize | 'labelLarge', Partial<MD3Type>> = {
  largeRegular: {
    fontFamily: 'Maersk-Regular',
    fontWeight: '400',
    fontSize: fontSizes.lg
  },
  largeBold: {
    fontFamily: 'Maersk-Bold',
    fontWeight: '500',
    fontSize: fontSizes.lg
  },
  mediumRegular: {
    fontFamily: 'Maersk-Regular',
    fontWeight: '400',
    fontSize: fontSizes.md
  },
  mediumBold: mediumBoldConfig,
  smallRegular: {
    fontFamily: 'Maersk-Regular',
    fontWeight: '400',
    fontSize: fontSizes.sm
  },
  smallBold: {
    fontFamily: 'Maersk-Bold',
    fontWeight: '500',
    fontSize: fontSizes.sm
  },
  extraSmallRegular: {
    fontFamily: 'Maersk-Regular',
    fontWeight: '400',
    fontSize: fontSizes.xs
  },
  extraSmallBold: {
    fontFamily: 'Maersk-Bold',
    fontWeight: '400',
    fontSize: fontSizes.xs
  },
  // This style is used for buttons from paper package
  labelLarge: mediumBoldConfig
}

export const theme = deepmerge(CombinedDefaultTheme, {
  roundness: 1,
  fonts: configureFonts({
    config: fontConfig
  }),
  colors: {
    primary: colors.orange,
    outline: colors.orange,
    background: colors.white,
    surfaceDisabled: colors.orange20,
    onSurfaceDisabled: colors.white,
    error: colors.denied,
    onSurface: colors.primaryDarkGray
  }
})
