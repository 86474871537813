// External
import { View } from 'react-native'
// Components
import { Text } from '@/components/common'
// Constants
import { colors } from '@/constants'

interface Props {
  index: number
  currentStep: number
  steps: string[]
}

export const Step = ({ index, currentStep, steps }: Props) => {
  let color = colors.neutralGray

  if (index === currentStep) {
    color = colors.orange
  }

  if (index < currentStep) {
    color = colors.mediumGray
  }

  return (
    <View
      style={{
        flex: 1,
        alignItems: 'center'
      }}
    >
      <View
        style={{
          width: 32,
          height: 32,
          borderRadius: 16,
          backgroundColor: color,
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Text style={{ color: colors.white }} variant="baseBold">
          {index + 1}
        </Text>
      </View>

      <Text
        style={{ color, marginTop: 8, marginBottom: 12 }}
        variant="baseBold"
      >
        {steps[index]}
      </Text>

      <View
        style={{
          width: '100%',
          height: 4,
          backgroundColor: color,
          borderRadius: 2
        }}
      />
    </View>
  )
}
