export const homeTabsSVGPaths = {
  CalendarStack:
    'M6.772.075 6.697 0H5.375L5.3 2.063l-1.913-.076-.231.01A3.393 3.393 0 0 0 0 5.375v15.235l.007.233A3.393 3.393 0 0 0 3.387 24H20.61l.233-.008a3.393 3.393 0 0 0 3.155-3.379l-.001-15.24-.008-.231a3.391 3.391 0 0 0-3.379-3.153h-1.988l.076-1.914L17.295 0l-.075 2.063-10.522-.076.074-1.912ZM1.474 20.61V8.685l21.046.075.001 11.85-.009.195a1.914 1.914 0 0 1-1.902 1.716H3.387l-.195-.009a1.914 1.914 0 0 1-1.718-1.902ZM18.697 3.389l-.075 1.399-1.398-.075.075-1.251-10.525-.075-.075 1.398-1.396-.075-.001-1.25H3.387c-.99 0-1.804.755-1.903 1.718l-.01.195L1.4 7.288l21.123.075v-1.99c0-.99-.754-1.805-1.717-1.902l-.195-.01-1.914-.075v.002Zm-7.562 10.1H5.29a.745.745 0 1 1 0-1.49h5.965a.745.745 0 1 1 0 1.49h-.12Z',
  HistoryStack:
    'M13.5 0A4.5 4.5 0 0 1 18 4.5v17.25a.75.75 0 0 1-.75.75H.75a.75.75 0 0 1-.75-.75v-21A.75.75 0 0 1 .75 0H13.5Zm-12 21h15V4.5a3 3 0 0 0-2.775-2.992L13.5 1.5h-12V21ZM15 6.75a.75.75 0 0 0-.75-.75H3.75a.75.75 0 0 0 0 1.5h10.5a.75.75 0 0 0 .75-.75ZM9.75 9a.75.75 0 1 1 0 1.5h-6a.75.75 0 1 1 0-1.5h6Zm.75 3.75a.75.75 0 0 0-.75-.75h-6a.75.75 0 1 0 0 1.5h6a.75.75 0 0 0 .75-.75Z',
  Notifications:
    'M4.589 6.638C5.264 3.124 7.944 0 12 0c4.056 0 6.735 3.124 7.412 6.638l1.224 6.346c.255 1.317.808 1.66 1.525 2.106l.052.033.15.091c.33.203.689.422 1.009.777.367.41.628 1.018.628 1.738v1.515a.709.709 0 0 1-.705.712h-7.068a.73.73 0 0 1 .008.125v.155C16.235 22.372 14.28 24 12 24c-2.28 0-4.236-1.628-4.236-3.765v-.154a.72.72 0 0 1 .009-.125H.705A.71.71 0 0 1 0 19.244v-1.515c0-.72.261-1.328.63-1.737.318-.356.675-.575 1.008-.778l.15-.091.051-.033c.717-.445 1.272-.789 1.526-2.106l1.224-6.346Zm4.579 13.318c.007.041.01.083.009.125v.155c0 1.244 1.193 2.339 2.823 2.339s2.823-1.095 2.823-2.34v-.154c0-.043.003-.084.009-.125H9.168Zm8.855-13.052C17.445 3.908 15.35 1.425 12 1.425c-3.351 0-5.445 2.482-6.022 5.48l-1.223 6.347c-.34 1.77-1.443 2.595-2.227 3.085l-.038.022c-.373.232-.661.412-.823.59-.167.184-.254.348-.254.78v.802H22.59v-.802c0-.434-.087-.596-.255-.78-.16-.178-.45-.358-.822-.59l-.038-.023c-.784-.488-1.887-1.313-2.227-3.083l-1.226-6.349Z',
  Profile:
    'M10 2a4.16 4.16 0 1 0-.158 8.317A4.16 4.16 0 0 0 10 2ZM4.34 6.16a5.66 5.66 0 1 1 11.32 0 5.66 5.66 0 0 1-11.32 0Zm-.067 8.576A2.523 2.523 0 0 0 1.75 17.26v2.522h16.5V17.26a2.522 2.522 0 0 0-2.523-2.524H4.273ZM.25 17.26c0-2.221 1.8-4.023 4.023-4.023h11.454c2.221 0 4.023 1.8 4.023 4.023v4.022H.25V17.26Z'
}
