// External
import { create } from 'zustand'

interface UnsubscribeState {
  unsubscribeFunctions: Array<() => void>
  addUnsubscribeFunction: (unsubscribe: () => void) => void
  unsubscribeAll: () => void
}

const useUnsubscribeStore = create<UnsubscribeState>((set, get) => ({
  unsubscribeFunctions: [],
  addUnsubscribeFunction: (unsubscribe) => {
    set((state) => ({
      unsubscribeFunctions: [...state.unsubscribeFunctions, unsubscribe]
    }))
  },
  unsubscribeAll: () => {
    const unsubscribeFunctions = get().unsubscribeFunctions
    // console.log(`Running ${unsubscribeFunctions.length} unsubscribe functions`)

    unsubscribeFunctions.forEach((unsubscribe) => {
      unsubscribe()
    })

    set(() => ({
      unsubscribeFunctions: []
    }))
  }
}))

export default useUnsubscribeStore
