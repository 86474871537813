// External
import { useEffect } from 'react'
import { Platform } from 'react-native'
import OneSignal from 'react-native-onesignal'
import { doc, onSnapshot, collection, query, where } from 'firebase/firestore'
// Config
import { auth, db } from '@/config/firebase'
// Hooks
import useAppStore from '@/store/useAppStore'
import { type User } from '@/models/user.interface'
// Store
import useUnsubscribeStore from '@/store/useUnsubscribeStore'
// Use cases
import { createOneSignalSubscriptions } from '@/useCases/user.use-case'

const isMobile = Platform.OS === 'android' || Platform.OS === 'ios'

const useUser = () => {
  const setIsLoggedIn = useAppStore((state) => state.setIsLoggedIn)
  const setUser = useAppStore((state) => state.setUser)
  const setUnreadNotifications = useAppStore(
    (state) => state.setUnreadNotifications
  )
  const addUnsubscribeFunction = useUnsubscribeStore(
    (state) => state.addUnsubscribeFunction
  )

  useEffect(() => {
    let userSnapshotUnsubscribe: () => void
    let notificationsSnapshotUnsubscribe: () => void

    const authUnsubscribe = auth.onAuthStateChanged((user) => {
      if (user != null) {
        const userRef = doc(db, 'users', user.uid)

        userSnapshotUnsubscribe = onSnapshot(userRef, (doc) => {
          if (doc.exists()) {
            const userData = doc.data() as User
            setUser(userData)
          } else {
            setUser(undefined)
          }
          setIsLoggedIn(true)
        })

        const notificationsRef = collection(userRef, 'notifications')
        const notificationsQuery = query(
          notificationsRef,
          where('read', '==', false)
        )

        notificationsSnapshotUnsubscribe = onSnapshot(
          notificationsQuery,
          (querySnapshot) => {
            setUnreadNotifications(querySnapshot.size)
          }
        )

        addUnsubscribeFunction(userSnapshotUnsubscribe)
        addUnsubscribeFunction(notificationsSnapshotUnsubscribe)

        void createOneSignalSubscriptions(user.uid)
      } else {
        setIsLoggedIn(false)
        setUser(undefined)

        if (isMobile) {
          OneSignal.removeExternalUserId()
          OneSignal.logoutEmail()
          OneSignal.logoutSMSNumber()
        }
      }

      return () => {
        authUnsubscribe()
        userSnapshotUnsubscribe()
        notificationsSnapshotUnsubscribe()
      }
    })
  }, [])
}
export default useUser
