// External
import {
  doc,
  updateDoc,
  collection,
  query,
  where,
  getDocs,
  writeBatch,
  type DocumentReference
} from 'firebase/firestore'
// Config
import { db } from '@/config/firebase'
// Models
import { type Request, type RequestUpdate } from '@/models'

export const createRequest = async (requests: Request[]) => {
  const batch = writeBatch(db)
  const refs: DocumentReference[] = []

  requests.forEach((request) => {
    const requestRef = doc(collection(db, 'requests'))
    batch.set(requestRef, request)
    refs.push(requestRef)
  })

  try {
    await batch.commit()
    return refs.map((ref) => ref.id)
  } catch (error) {
    console.error('Error creating requests:', error)
    return await Promise.reject(error)
  }
}

export const getConflictingRequests = async (request: Request) => {
  try {
    const { user, dateFrom, dateTo } = request

    const userRequestsRef = collection(db, 'requests')
    const userRequestsQuery = query(
      userRequestsRef,
      where('user.email', '==', user.email),
      where('status', 'in', ['pending', 'approved', 'denied'])
    )
    const userRequestsSnapshot = await getDocs(userRequestsQuery)

    const conflictingRequests: Request[] = []
    userRequestsSnapshot.forEach((doc) => {
      const existingRequest = doc.data() as Request

      if (
        dateFrom.toMillis() <= existingRequest.dateTo.toMillis() &&
        dateTo.toMillis() >= existingRequest.dateFrom.toMillis()
      ) {
        conflictingRequests.push(existingRequest)
      }
    })
    console.log(
      'Conflicting requests:',
      conflictingRequests.map((request) => ({
        status: request.status
      }))
    )

    return conflictingRequests
  } catch (error) {
    console.error('Error getting conflicting requests:', error)
    return await Promise.reject(error)
  }
}

export const updateRequest = async ({
  id,
  fields
}: {
  id: string
  fields: RequestUpdate
}) => {
  try {
    const requestRef = doc(db, 'requests', id)
    await updateDoc(requestRef, fields)
  } catch (error) {
    console.error('Error editing request:', error)
    return await Promise.reject(error)
  }
}

export const updateRequests = async (
  updates: Array<{ id: string; fields: RequestUpdate }>
) => {
  const batch = writeBatch(db)

  updates.forEach(({ id, fields }) => {
    const requestRef = doc(db, 'requests', id)
    batch.update(requestRef, fields)
  })

  try {
    await batch.commit()
  } catch (error) {
    console.error('Error updating requests:', error)
    return await Promise.reject(error)
  }
}
