const errors = [
  {
    message: 'auth/user-not-found',
    parsedMessage: 'Incorrect email or password'
  },
  {
    message: 'auth/wrong-password',
    parsedMessage: 'Incorrect email or password'
  },
  {
    message: 'auth/too-many-requests',
    parsedMessage: 'Account temporarily disabled, please reset your password'
  }
]

export const parseErrorMessage = (errorMessage: string) => {
  for (const error of errors) {
    if (errorMessage.includes(error.message)) {
      return error.parsedMessage
    }
  }

  return errorMessage
}
