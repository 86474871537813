// External
import { View } from 'react-native'
import Toast from 'react-native-toast-message'
import { Formik } from 'formik'
import { object, string, type InferType } from 'yup'
// Components
import { Button, Input, Text } from '@/components/common'
// Models
import { UseCaseError } from '@/models/use-case-error.class'
// Use cases
import { resetPassword } from '@/useCases/user.use-case'
//  Hooks
import usePortalStore from '@/store/usePortalStore'

export const ResetPassword = () => {
  const hideModal = usePortalStore((state) => state.hideModal)

  const resetPasswordSchema = object({
    email: string()
      .email('Please enter a valid email')
      .required('Please enter your email')
  })

  type ResetPasswordType = InferType<typeof resetPasswordSchema>

  return (
    <Formik
      initialValues={{ email: '' }}
      validationSchema={resetPasswordSchema}
      onSubmit={async ({ email }) => {
        try {
          const res = await resetPassword(email)
          Toast.show({
            type: 'success',
            text1: res.title
          })
          hideModal()
        } catch (error) {
          if (error instanceof UseCaseError) {
            Toast.show({
              type: 'error',
              text1: error.title,
              text2: error.message
            })
          }
        }
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
        touched,
        isSubmitting,
        isValid
      }) => (
        <View>
          <Text variant="h2Bold">RESET YOUR PASSWORD</Text>

          <Input<ResetPasswordType>
            name="email"
            textLabel="Email"
            value={values.email}
            style={{ marginBottom: 24, marginTop: 32 }}
            onChangeText={handleChange('email')}
            onBlur={handleBlur('email')}
            inputMode="email"
            autoCapitalize="none"
            errors={errors}
            touched={touched}
          />

          <Button
            disabled={!isValid}
            loading={isSubmitting}
            onPress={() => {
              handleSubmit()
            }}
          >
            {isSubmitting ? 'Sending email' : 'Send email'}
          </Button>
        </View>
      )}
    </Formik>
  )
}
