// External
import { type FontSize, type FontVariant } from '@/models'
import { type ReactNode } from 'react'
import { type TextStyle, type ViewStyle } from 'react-native'
import { customText } from 'react-native-paper'

interface TextProps {
  children: ReactNode
  variant?: FontVariant
  color?: string
  style?: TextStyle | TextStyle[] | ViewStyle
  numberOfLines?: number
}

export const Text = ({
  children,
  variant = 'baseRegular',
  color,
  style,
  numberOfLines
}: TextProps) => {
  const stylesMap: Partial<Record<FontVariant, TextStyle>> = {
    h2Regular: {
      textTransform: 'uppercase'
    },
    h2Bold: {
      textTransform: 'uppercase'
    },
    link: {
      textDecorationLine: 'underline'
    },
    smallLinkRegular: {
      textDecorationLine: 'underline'
    },
    smallLinkBold: {
      textDecorationLine: 'underline'
    },
    caption: {
      textTransform: 'uppercase',
      letterSpacing: 1.8
    }
  }

  const variantMap: Record<FontVariant, FontSize> = {
    h1Regular: 'largeRegular',
    h1Bold: 'largeBold',
    h2Regular: 'mediumRegular',
    h2Bold: 'mediumBold',
    caption: 'mediumBold',
    link: 'mediumBold',
    mediumRegular: 'mediumRegular',
    mediumBold: 'mediumBold',
    baseRegular: 'smallRegular',
    baseBold: 'smallBold',
    smallLinkRegular: 'smallRegular',
    smallLinkBold: 'smallBold',
    smallRegular: 'extraSmallRegular',
    smallBold: 'extraSmallBold'
  }

  const textStyle = stylesMap[variant] !== undefined ? stylesMap[variant] : {}
  const textVariant = variantMap[variant]

  const PaperText = customText<FontSize>()

  return (
    <PaperText
      variant={textVariant}
      style={[textStyle, style]}
      theme={color !== undefined ? { colors: { onSurface: color } } : undefined}
      numberOfLines={numberOfLines}
    >
      {children}
    </PaperText>
  )
}
