// External
import { View } from 'react-native'
import Svg, { Path } from 'react-native-svg'
// Components
import { Text } from '@/components/common'
// Constants
import { colors, homeTabsSVGPaths } from '@/constants'

interface Props {
  focused: boolean
  label: string
  routeName: string
}

export const TabBarIcon = ({ focused, label, routeName }: Props) => {
  return (
    <View style={{ alignItems: 'center', marginVertical: 12 }}>
      <Svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
      >
        <Path
          fill={colors.orange}
          fillRule="evenodd"
          d={homeTabsSVGPaths[routeName as keyof typeof homeTabsSVGPaths]}
          clipRule="evenodd"
        />
      </Svg>

      <Text
        color={focused ? colors.orange : colors.primaryDarkGray}
        variant={focused ? 'baseBold' : 'baseRegular'}
        style={{ marginTop: 12 }}
      >
        {label}
      </Text>
    </View>
  )
}
