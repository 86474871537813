import { FirebaseError } from 'firebase/app'

export class UseCaseError extends Error {
  title: string
  message: string

  constructor(error: unknown) {
    super()
    if (error instanceof FirebaseError) {
      this.title = 'Something went wrong'
      this.message = error.message
    } else if (
      error instanceof Error &&
      error.message.includes(
        'You already have requests for the selected date range'
      )
    ) {
      this.title = 'Conflicting time off requests dates'
      this.message = 'Please review your history'
    } else {
      this.title = 'Unknown error'
      this.message = 'Please try again, if the error persists, contact us'
    }
  }
}
