// External
import { useState } from 'react'
import { View, TextInput } from 'react-native'
import { Divider } from 'react-native-paper'
import Toast from 'react-native-toast-message'
// Components
import { Text, Link } from '@/components/common'
// Config
import { colors } from '@/constants'
// Models
import { type OnPress } from '@/models'
// Use case
import { editProfileField } from '@/useCases/user.use-case'

interface Props {
  label: string
  name?: string
  value: string
  editable?: boolean
  linkOnPress?: OnPress
  linkText?: string
}

export const InfoField = ({
  label,
  name,
  value,
  editable,
  linkOnPress,
  linkText
}: Props) => {
  const [currentValue, setCurrentValue] = useState(value)

  const submit = async () => {
    try {
      if (name !== undefined) {
        await editProfileField({ name, value: currentValue })
      }
    } catch (error) {
      setCurrentValue(value)
      Toast.show({
        type: 'error',
        text1: `${label} could not be saved`,
        text2: 'Please try again'
      })
    }
  }

  return (
    <>
      <View
        style={{
          flexDirection: 'row',
          alignContent: 'space-between',
          paddingVertical: 18
        }}
      >
        <View
          style={{
            flex: 1,
            flexDirection: 'row',
            alignItems: 'center',
            gap: 6
          }}
        >
          <Text variant="baseBold">{label}:</Text>

          {editable === undefined || !editable ? (
            <Text variant="baseRegular" color={colors.neutralGray}>
              {value}
            </Text>
          ) : (
            <TextInput
              style={{ fontFamily: 'Maersk-Regular' }}
              value={currentValue}
              onChangeText={setCurrentValue}
              onSubmitEditing={submit}
              onBlur={submit}
            />
          )}
        </View>

        {linkOnPress !== undefined && linkText !== undefined ? (
          <Link text={linkText} onPress={linkOnPress} />
        ) : null}
      </View>

      <Divider />
    </>
  )
}
