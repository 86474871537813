export default {
  orange: '#FF6441',
  orange60: '#FF644199',
  orange20: '#FF644033',
  blue: '#004165',
  primaryDarkGray: '#3C3C46',
  darkGray: '#6F7A85',
  mediumGray: '#6F7A85',
  secondaryMediumGray: '#5A6E7D',
  neutralGray: '#AAB4C3',
  lightGray: '#E6EBF2',
  white: '#FFFFFF',
  denied: '#D75E72',
  pending: '#EEAF30',
  approved: '#6FCF97',
  cancelled: '#AAB4C3'
}
