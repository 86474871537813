// External
import { useCallback } from 'react'
import { FlatList, RefreshControl } from 'react-native'
import { useFocusEffect } from '@react-navigation/native'
// Components
import { Text } from '@/components/common'
import { NotificationSummary } from '@/components/notifications'
// Constants
import { colors } from '@/constants'
// Config
import { auth } from '@/config/firebase'
// Hooks
import useNotifications from '@/hooks/useNotifications'
// Layouts
import { LoadingContent, SafeArea } from '@/layouts'
// Models
import type { NotificationWithID } from '@/models'
// Use cases
import { markAllNotificationsAsRead } from '@/useCases/notification.use-case'

const Notifications = () => {
  const { notifications, status, refresh } = useNotifications()

  const renderItem = useCallback(({ item }: { item: NotificationWithID }) => {
    return <NotificationSummary key={item.id} notification={item} />
  }, [])

  useFocusEffect(
    useCallback(() => {
      // Notifications should only be marked as read when they're rendered. Using unreadNotifications from the zustand's store caused the notifications to be
      // marked as read before they were rendered, which caused the notification badge to be removed before the user could see them
      const unreadNotifications = notifications.filter(
        (notification) => !notification.read
      )

      if (auth.currentUser !== null && unreadNotifications.length > 0) {
        // console.log(
        //   `Marking ${unreadNotifications.length} notifications as read`
        // )
        void markAllNotificationsAsRead(auth.currentUser.uid)
      }
    }, [notifications])
  )

  return (
    <SafeArea disabledInsets={['bottom']}>
      <Text variant="h2Bold" style={{ textAlign: 'center', marginBottom: 40 }}>
        NOTIFICATIONS
      </Text>

      <LoadingContent
        loading={status === 'loading'}
        message="Loading your notifications"
      >
        <FlatList
          refreshControl={
            <RefreshControl
              refreshing={status === 'refreshing'}
              onRefresh={refresh}
            />
          }
          data={notifications}
          renderItem={renderItem}
          contentContainerStyle={{
            flexGrow: 1,
            paddingHorizontal: 24,
            paddingBottom: 16
          }}
          ListEmptyComponent={
            <Text
              color={colors.darkGray}
              style={{
                marginTop: 'auto',
                marginBottom: 'auto',
                textAlign: 'center'
              }}
            >
              You have no notifications
            </Text>
          }
        />
      </LoadingContent>
    </SafeArea>
  )
}
export default Notifications
