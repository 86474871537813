// External
import { View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
// Components
import { Link, Hero, Text } from '@/components/common'
// Utils
import { logOut } from '@/utils/logOut'

const AccountVerification = () => {
  const { top, left, bottom, right } = useSafeAreaInsets()

  return (
    <View style={{ flex: 1, paddingTop: top, paddingBottom: bottom }}>
      <Hero
        intro="Account"
        title="Created"
        introTitleStyle={{ textAlign: 'center' }}
      />

      <View
        style={{
          flex: 1,
          paddingLeft: left + 24,
          paddingRight: right + 24
        }}
      >
        <Text style={{ marginBottom: 'auto', textAlign: 'center' }}>
          Your account is under verification. You will receive a notification
          via email when it's ready to use
        </Text>

        <Link
          onPress={() => {
            void logOut()
          }}
          text="Go back to log in"
          style={{ alignSelf: 'center', marginBottom: 120 }}
        />
      </View>
    </View>
  )
}
export default AccountVerification
