enum Roles {
  ADMIN = 'admin',
  SUPER_ADMIN = 'superAdmin'
}

export interface AdminUser {
  id?: string
  email: string
  firstName: string
  lastName: string
  profilePhoto: string
  role: Roles | string
}
