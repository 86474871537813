// External
import { Keyboard, Pressable, type ViewStyle, Platform } from 'react-native'
// Components
import { Step } from './Step'

interface Props {
  currentStep: number
  steps: string[]
  style?: ViewStyle
}

export const Steps = ({ currentStep, steps, style }: Props) => {
  return (
    <Pressable
      style={[
        {
          flexDirection: 'row',
          gap: 24,
          paddingBottom: 40
        },
        style
      ]}
      onPress={() => {
        if (Platform.OS !== 'web' && Keyboard.isVisible()) {
          Keyboard.dismiss()
        }
      }}
    >
      {steps.map((step, index) => (
        <Step
          key={steps[index]}
          index={index}
          currentStep={currentStep}
          steps={steps}
        />
      ))}
    </Pressable>
  )
}
