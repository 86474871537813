// External
import {
  Dimensions,
  StyleSheet,
  type ViewStyle,
  ImageBackground
} from 'react-native'
import { type ReactNode } from 'react'

interface Props {
  style?: ViewStyle
  children: ReactNode
}

export const BaseHero = ({ style, children }: Props) => {
  const windowWidth =
    Dimensions.get('window').width > 800 ? 800 : Dimensions.get('window').width
  const ratio = 1.3

  return (
    <ImageBackground
      style={[styles.container, { maxHeight: windowWidth / ratio }, style]}
      defaultSource={require('@assets/images/stripes.png')}
      source={require('@assets/images/bg.png')}
    >
      {children}
    </ImageBackground>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingTop: 120,
    justifyContent: 'center'
  }
})
