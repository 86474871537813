// External
import { useEffect } from 'react'
import { Platform } from 'react-native'
import Constants from 'expo-constants'
import OneSignal from 'react-native-onesignal'

const useOneSignal = () => {
  useEffect(() => {
    if (Platform.OS === 'android' || Platform.OS === 'ios') {
      OneSignal.setAppId(Constants?.expoConfig?.extra?.oneSignalAppId)
      OneSignal.setNotificationOpenedHandler((notification) => {
        console.log('OneSignal: notification opened:', notification)
      })

      return () => {
        OneSignal.clearEmailSubscriptionObservers()
        OneSignal.clearSMSSubscriptionObservers()
      }
    }
  }, [])
}

export default useOneSignal
