// External
import { TouchableOpacity, Keyboard, Platform } from 'react-native'
import { IconButton } from 'react-native-paper'
// Components
import { Text } from '@/components/common'
import { RequestRestrictions } from '@/components/modal'
// Constants
import { colors } from '@/constants'
// Store
import usePortalStore from '@/store/usePortalStore'

export const RestrictionWarnings = () => {
  const showModal = usePortalStore((state) => state.showModal)

  return (
    <TouchableOpacity
      style={{
        flexDirection: 'row',
        justifyContent: 'center',
        paddingBottom: 12
      }}
      onPress={() => {
        if (Platform.OS !== 'web' && Keyboard.isVisible()) {
          Keyboard.dismiss()
        }

        showModal({ content: <RequestRestrictions />, hideCloseButton: true })
      }}
    >
      <Text
        variant="baseBold"
        style={{ textDecorationLine: 'underline' }}
        color={colors.pending}
      >
        View Request Restrictions
      </Text>

      <IconButton
        size={12}
        iconColor={colors.pending}
        icon="information"
        style={{ marginTop: -6, marginLeft: -4 }}
      />
    </TouchableOpacity>
  )
}
