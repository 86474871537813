import { Platform } from 'react-native'
import { initializeApp } from 'firebase/app'
import { initializeAuth, browserLocalPersistence } from 'firebase/auth'
import { getReactNativePersistence } from 'firebase/auth/react-native'
import { initializeFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'
import Constants from 'expo-constants'
import AsyncStorage from '@react-native-async-storage/async-storage'

const isMobile = Platform.OS === 'android' || Platform.OS === 'ios'

const firebase = initializeApp(Constants?.expoConfig?.extra?.firebaseConfig)

const persistence = isMobile
  ? getReactNativePersistence(AsyncStorage)
  : browserLocalPersistence

export const auth = initializeAuth(firebase, { persistence })
// Fixes Firestore timeout on dev clients
export const db = initializeFirestore(firebase, {
  experimentalForceLongPolling: true
})
export const storage = getStorage(firebase)
