// Models
import { UseCaseError } from '@/models/use-case-error.class'
// Services
import {
  getNotifications,
  updateNotifications
} from '@/services/notification.service'

export const listNotifications = async (userId: string) => {
  try {
    const notifications = await getNotifications(userId)
    return {
      data: notifications
    }
  } catch (error) {
    return await Promise.reject(new UseCaseError(error))
  }
}

export const markAllNotificationsAsRead = async (userId: string) => {
  try {
    const notifications = await getNotifications(userId)
    const notificationIds = notifications.map((notification) => notification.id)
    void updateNotifications({
      userId,
      notificationIds,
      fields: { read: true }
    })
  } catch (error) {
    return await Promise.reject(new UseCaseError(error))
  }
}
