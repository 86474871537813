// External
import type { CameraCapturedPicture } from 'expo-camera'
import type { ImagePickerAsset } from 'expo-image-picker'
import { Platform } from 'react-native'
import Constants from 'expo-constants'
import OneSignal from 'react-native-onesignal'
import { Timestamp } from 'firebase/firestore'
import { signOut } from 'firebase/auth'
// Config
import { auth } from '@/config/firebase'
// Models
import { type PendingUserWithPassword, UseCaseError } from '@/models'
// Services
import {
  createUser,
  createUserInFirestore,
  sendPasswordResetEmail,
  deleteAccount,
  updatePassword,
  updateUser,
  getUser
} from '@/services/user.service'
import { uploadAvatar } from '@/services/storage.service'
// Stores
import useUnsubscribeStore from '@/store/useUnsubscribeStore'
import useAppStore from '@/store/useAppStore'

export const signUp = async ({
  email,
  firstName,
  lastName,
  phoneNumber,
  waterfront,
  seniority,
  password
}: Omit<PendingUserWithPassword, 'createdAt'>) => {
  try {
    let appVersion = ''
    let isPushEnabled = false

    if (typeof Constants.expoConfig?.version === 'string') {
      appVersion = Constants.expoConfig.version
    }

    if (Platform.OS === 'web') {
      const deviceState = await OneSignal.getDeviceState()
      isPushEnabled = !(deviceState?.isPushDisabled ?? false)
    }

    await createUser({ email, password })
    await createUserInFirestore({
      email,
      firstName,
      lastName,
      phoneNumber,
      waterfront,
      seniority,
      createdAt: Timestamp.now(),
      appVersion,
      isPushEnabled,
      playerIDs: []
    })

    return
  } catch (error) {
    return await Promise.reject(new UseCaseError(error))
  }
}

export const resetPassword = async (email: string) => {
  try {
    await sendPasswordResetEmail(email)
    return {
      title: 'Reset password email sent',
      message: "If you can't find it check your spam folder"
    }
  } catch (error) {
    return await Promise.reject(new UseCaseError(error))
  }
}

export const editAvatar = async ({
  id,
  avatar
}: {
  id: string
  avatar: CameraCapturedPicture | ImagePickerAsset
}) => {
  try {
    const avatarDownloadURL = await uploadAvatar({
      id,
      avatar
    })
    await updateUser({
      fields: { profilePhoto: avatarDownloadURL },
      collection: 'users'
    })
  } catch (error) {
    return await Promise.reject(new UseCaseError(error))
  }
}

export const editPassword = async (password: string) => {
  try {
    await updatePassword(password)
    return {
      title: 'Password updated successfully'
    }
  } catch (error) {
    return await Promise.reject(new UseCaseError(error))
  }
}

export const editProfileField = async ({
  name,
  value
}: {
  name: string
  value: string
}) => {
  try {
    await updateUser({ fields: { [name]: value }, collection: 'users' })
  } catch (error) {
    return await Promise.reject(new UseCaseError(error))
  }
}

export const editPhone = async ({
  phonePrefix,
  phoneNumber
}: {
  phonePrefix: number
  phoneNumber: number
}) => {
  try {
    await updateUser({
      fields: {
        phoneNumber: {
          prefix: phonePrefix,
          number: phoneNumber
        }
      },
      collection: 'users'
    })
    return {
      title: 'Phone number updated successfully'
    }
  } catch (error) {
    return await Promise.reject(new UseCaseError(error))
  }
}

export const updateAppData = async ({
  appVersion,
  isPushEnabled
}: {
  appVersion: string
  isPushEnabled: boolean
}) => {
  if (auth.currentUser !== null) {
    const res = await getUser(auth.currentUser.uid)
    if (res !== undefined) {
      await updateUser({
        fields: { appVersion, isPushEnabled },
        collection: res.isPending ? 'pendingUsers' : 'users'
      })
    }
  }
}

export const deleteUserAccount = async (id: string) => {
  try {
    await deleteAccount(id)

    useUnsubscribeStore.getState().unsubscribeAll()
    useAppStore.getState().setIsLoggedIn(false)
    useAppStore.getState().setUser(undefined)

    void signOut(auth)

    return {
      title: 'Account deleted successfully',
      message: 'We are sorry to see you go'
    }
  } catch (error) {
    return await Promise.reject(new UseCaseError(error))
  }
}

// Used https://documentation.onesignal.com/reference/add-a-device v9.0
export const createOneSignalSubscriptions = async (userId: string) => {
  const res = await getUser(userId)

  if (res === undefined) {
    return
  }

  const { user } = res

  const newPlayerIDs = []

  if (Platform.OS === 'web' && Constants.expoConfig?.extra !== undefined) {
    const emailOptions = {
      method: 'POST',
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        app_id: Constants.expoConfig.extra.oneSignalAppId,
        device_type: 11,
        identifier: user.email,
        external_user_id: userId
      })
    }

    const emailRes = await fetch(
      'https://onesignal.com/api/v1/players',
      emailOptions
    )

    const identifier = `+${user.phoneNumber.prefix}${user.phoneNumber.number}`
    const smsOptions = {
      method: 'POST',
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        app_id: Constants.expoConfig.extra.oneSignalAppId,
        device_type: 14,
        identifier,
        external_user_id: userId
      })
    }

    const smsRes = await fetch(
      'https://onesignal.com/api/v1/players',
      smsOptions
    )

    const emailJSON = await emailRes.json()
    const smsJSON = await smsRes.json()

    if ('id' in emailJSON || 'id' in smsJSON) {
      if ('id' in emailJSON) {
        newPlayerIDs.push(emailJSON.id)
      }

      if ('id' in smsJSON) {
        newPlayerIDs.push(smsJSON.id)
      }

      await updateUser({
        fields: {
          playerIDs: [...new Set([...(user.playerIDs ?? []), ...newPlayerIDs])]
        },
        collection:
          'category' in user || 'list' in user ? 'users' : 'pendingUsers'
      })
    }

    return
  }

  OneSignal.setExternalUserId(userId)
  OneSignal.setEmail(user.email)
  OneSignal.setSMSNumber(
    `+${user.phoneNumber.prefix}${user.phoneNumber.number}`
  )
  const deviceState = await OneSignal.getDeviceState()

  if (deviceState !== null) {
    if ('emailUserId' in deviceState && deviceState.emailUserId !== '') {
      newPlayerIDs.push(deviceState.emailUserId)
    }

    if ('smsUserId' in deviceState && deviceState.smsUserId !== '') {
      newPlayerIDs.push(deviceState.smsUserId)
    }

    if ('userId' in deviceState && deviceState.userId !== '') {
      newPlayerIDs.push(deviceState.userId)
    }

    await updateUser({
      fields: {
        playerIDs: [...new Set([...(user.playerIDs ?? []), ...newPlayerIDs])]
      },
      collection:
        'category' in user || 'list' in user ? 'users' : 'pendingUsers'
    })
  }
}
