// External
import { useEffect, useCallback } from 'react'
import { FlatList } from 'react-native'
import { type StackScreenProps } from '@react-navigation/stack'
// Components
import { RequestCard, Text } from '@/components/common'
// Constants
import { colors } from '@/constants'
// Hooks
import useRequests from '@/hooks/useRequests'
// Layouts
import { SafeArea, LoadingContent } from '@/layouts'
// Models
import { type HistoryStackParamList } from '@/navigators/HistoryStack'
import { type RequestWithID } from '@/models'

type Props = StackScreenProps<HistoryStackParamList, 'History'>

const History = ({ navigation, route }: Props) => {
  const { loading, requests, loadMore } = useRequests()

  useEffect(() => {
    if (route.params?.id !== undefined && requests.length > 0) {
      const request = requests.find((request) => request.id === route.params.id)
      if (request !== undefined) {
        navigation.setParams({ id: undefined })
        navigation.navigate('RequestSummary', { request })
      }
    }
  }, [route.params, requests])

  const renderItem = useCallback(
    ({ item }: { item: RequestWithID }) => (
      <RequestCard
        key={item.id}
        request={item}
        size="regular"
        onPress={() => {
          navigation.navigate('RequestSummary', { request: item })
        }}
        style={{ marginBottom: 24 }}
      />
    ),
    []
  )

  return (
    <SafeArea disabledInsets={['top', 'bottom']} style={{ marginTop: 40 }}>
      <LoadingContent
        loading={loading}
        message="Loading your time off requests"
      >
        <FlatList
          contentContainerStyle={{ paddingHorizontal: 24 }}
          data={requests}
          initialNumToRender={10}
          renderItem={renderItem}
          onEndReached={loadMore}
          onEndReachedThreshold={2}
          ListEmptyComponent={
            <Text
              style={{
                marginTop: 'auto',
                marginBottom: 'auto',
                textAlign: 'center'
              }}
              color={colors.darkGray}
            >
              You have no time off requests
            </Text>
          }
        />
      </LoadingContent>
    </SafeArea>
  )
}
export default History
