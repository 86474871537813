import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import type { ReactNode } from 'react'
import { type ViewStyle } from 'react-native'

interface Props {
  children: ReactNode
  contentContainerStyle?: ViewStyle
}

export const KeyboardFix = ({ children, contentContainerStyle }: Props) => {
  return (
    <KeyboardAwareScrollView
      enableOnAndroid
      keyboardShouldPersistTaps="handled"
      style={{ flex: 1 }}
      contentContainerStyle={[{ flexGrow: 1 }, contentContainerStyle]}
      bounces={false}
    >
      {children}
    </KeyboardAwareScrollView>
  )
}
