// External
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'
import { type CameraCapturedPicture } from 'expo-camera'
import type { ImagePickerAsset } from 'expo-image-picker'
import type { DocumentPickerAsset } from 'expo-document-picker'
// Config
import { storage } from '@/config/firebase'
// Utils
import { getFilename } from '@/utils'

interface Parameters {
  files: Array<DocumentPickerAsset | ImagePickerAsset | CameraCapturedPicture>
  uploadPath: string
}

export const uploadFiles = async ({ files, uploadPath }: Parameters) => {
  try {
    const filesWithDownloadURL = []
    for (const file of files) {
      const { uri } = file
      const filename = getFilename(file)
      const storageRef = ref(storage, `${uploadPath}/${filename}`)

      const response = await fetch(uri)
      const blob = await response.blob()

      const uploadSnapshot = await uploadBytes(storageRef, blob)

      const downloadURL = await getDownloadURL(uploadSnapshot.ref)
      filesWithDownloadURL.push({
        extension: file.uri.substring(file.uri.lastIndexOf('.') + 1),
        filename,
        size: blob.size,
        url: downloadURL
      })
    }
    return filesWithDownloadURL
  } catch (error) {
    console.error('Error uploading files:', error)
    return await Promise.reject(error)
  }
}

export const uploadAvatar = async ({
  id,
  avatar
}: {
  id: string
  avatar: CameraCapturedPicture | ImagePickerAsset
}) => {
  try {
    const storageRef = ref(storage, `users/${id}/profilePhoto.jpg`)

    const response = await fetch(avatar.uri)
    const blob = await response.blob()

    const uploadSnapshot = await uploadBytes(storageRef, blob)

    return await getDownloadURL(uploadSnapshot.ref)
  } catch (error) {
    console.error('Error uploading avatar:', error)
    return await Promise.reject(error)
  }
}
