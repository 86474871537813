// External
import { Platform, View } from 'react-native'
import { type StackScreenProps } from '@react-navigation/stack'
import { type PreHomeStackParamList } from '@/navigators/PreHomeStack'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
// Components
import { Hero, FancyButton, Text } from '@/components/common'
// Hooks
import useAppStore from '@/store/useAppStore'

type Props = StackScreenProps<PreHomeStackParamList, 'Welcome'>

const Welcome = ({ navigation }: Props) => {
  const setShowWelcomeScreen = useAppStore(
    (state) => state.setShowWelcomeScreen
  )
  const { top, left, bottom, right } = useSafeAreaInsets()

  return (
    <View
      style={{
        flex: 1,
        paddingTop: top,
        paddingBottom: bottom,
        justifyContent: 'space-between'
      }}
    >
      <Hero intro="Welcome to" title="APM Labor" subtitle="Availability" />

      <Text
        style={{
          marginTop: 80,
          marginBottom: 24,
          marginLeft: left + 24,
          marginRight: right + 24
        }}
      >
        Thanks to this app you will be able to request your day off and keep
        track of all your petitions. In order to provide you with a smooth
        experience, we need you to enable some permissions
      </Text>

      <View style={{ paddingLeft: left + 24, paddingRight: right + 24 }}>
        <FancyButton
          onPress={() => {
            navigation.navigate(
              Platform.OS === 'android' || Platform.OS === 'ios'
                ? 'NotificationPermissions'
                : 'Login'
            )
            setShowWelcomeScreen(false)
          }}
          style={{
            marginBottom: 120
          }}
        >
          Let's get started
        </FancyButton>
      </View>
    </View>
  )
}
export default Welcome
