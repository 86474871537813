// External
import { type ViewStyle, View, type TextStyle } from 'react-native'
// Components
import { Text } from './Text'
import { BaseHero } from './BaseHero'
// Constants
import { colors } from '@/constants'

interface Props {
  style?: ViewStyle
  introTitleStyle?: TextStyle
  intro: string
  title: string
  subtitle?: string
}

export const Hero = ({
  style,
  introTitleStyle = { marginLeft: 40 },
  intro,
  title,
  subtitle = ' '
}: Props) => {
  return (
    <BaseHero style={style}>
      <View style={{ alignSelf: 'center' }}>
        <Text variant="h1Regular" style={introTitleStyle} color={colors.blue}>
          {intro}
        </Text>

        <Text variant="h1Bold" style={introTitleStyle} color={colors.blue}>
          {title}
        </Text>

        {subtitle !== undefined && (
          <Text
            style={{ marginLeft: 160 }}
            variant="h1Bold"
            color={colors.blue}
          >
            {subtitle}
          </Text>
        )}
      </View>
    </BaseHero>
  )
}
