// External
import { object, string, type InferType } from 'yup'
import { Formik } from 'formik'
import { View } from 'react-native'
import Toast from 'react-native-toast-message'
// Components
import { Text, Button, DropdownSelect, Input } from '@/components/common'
// Constants
import { phoneNumberRegEx, countryPrefixOptions } from '@/constants'
// Store
import usePortalStore from '@/store/usePortalStore'
// Use cases
import { editPhone } from '@/useCases/user.use-case'
import { UseCaseError } from '@/models'

interface Props {
  currentPhoneNumber: {
    prefix: number
    number: number
  }
}

export const EditPhone = ({ currentPhoneNumber }: Props) => {
  const hideModal = usePortalStore((state) => state.hideModal)

  const editPhoneSchema = object({
    phoneNumber: string()
      .matches(phoneNumberRegEx.number, 'Phone number is too short')
      .required('Cell phone number is required')
  })

  type EditPhoneSchemaType = InferType<typeof editPhoneSchema>

  return (
    <Formik
      initialValues={{
        phonePrefix: currentPhoneNumber.prefix,
        phoneNumber: currentPhoneNumber.number.toString()
      }}
      validationSchema={editPhoneSchema}
      onSubmit={async ({ phonePrefix, phoneNumber }) => {
        try {
          const res = await editPhone({
            phonePrefix,
            phoneNumber: parseInt(phoneNumber)
          })
          Toast.show({ type: 'success', text1: res.title })
          hideModal()
        } catch (error) {
          if (error instanceof UseCaseError) {
            Toast.show({
              type: 'error',
              text1: error.title,
              text2: error.message
            })
          }
        }
      }}
    >
      {({
        handleSubmit,
        handleChange,
        setFieldValue,
        handleBlur,
        values,
        errors,
        touched,
        isSubmitting,
        isValid
      }) => (
        <View>
          <Text variant="h2Bold" style={{ marginBottom: 32 }}>
            EDIT YOUR PHONE NUMBER
          </Text>

          <DropdownSelect
            style={{ marginBottom: 16 }}
            textLabel="Cell phone number prefix"
            options={countryPrefixOptions}
            onSelect={({ value }) => {
              setFieldValue('phonePrefix', value)
            }}
            defaultValue={
              countryPrefixOptions.filter(
                (country) => country.value === currentPhoneNumber.prefix
              )[0]
            }
          />

          <Input<EditPhoneSchemaType>
            name="phoneNumber"
            textLabel="Cell phone number (min 7. characters)"
            onChangeText={handleChange('phoneNumber')}
            onBlur={handleBlur('phoneNumber')}
            value={values.phoneNumber}
            autoComplete="tel"
            inputMode="tel"
            touched={touched}
            errors={errors}
          />

          <Button
            style={{ marginTop: 40 }}
            disabled={!isValid || isSubmitting}
            loading={isSubmitting}
            onPress={() => {
              handleSubmit()
            }}
          >
            {isSubmitting ? 'Saving changes' : 'Save changes'}
          </Button>
        </View>
      )}
    </Formik>
  )
}
