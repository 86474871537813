// External
import { useRef, useEffect, useState, createRef } from 'react'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { Camera, type CameraCapturedPicture, FlashMode } from 'expo-camera'
import {
  View,
  Animated,
  BackHandler,
  Image,
  Dimensions,
  Platform,
  Keyboard
} from 'react-native'
import { IconButton } from 'react-native-paper'
import Toast from 'react-native-toast-message'
// Constants
import { colors } from '@/constants'
// Store
import usePortalStore from '@/store/usePortalStore'

const { height, width } = Dimensions.get('window')
const screenRatio = height / width

const CameraView = () => {
  const opacity = useRef(new Animated.Value(0.4)).current
  const { top, bottom } = useSafeAreaInsets()
  const [permission, requestPermissions] = Camera.useCameraPermissions()
  const camera = createRef<Camera>()
  const [isFlashOn, setIsFlashOn] = useState(false)
  const [picture, setPicture] = useState<CameraCapturedPicture>()
  const [imagePadding, setImagePadding] = useState(0)
  const [ratio, setRatio] = useState<string>()
  const [isRatioSet, setIsRatioSet] = useState(false)
  const { onPictureTaken, unmountCameraView } = usePortalStore((state) => ({
    onPictureTaken: state.onPictureTaken,
    unmountCameraView: state.unmountCameraView
  }))

  const startPulseAnimation = () => {
    Animated.loop(
      Animated.sequence([
        Animated.timing(opacity, {
          toValue: 1,
          duration: 1000,
          useNativeDriver: true
        }),
        Animated.timing(opacity, {
          toValue: 0.5,
          duration: 1000,
          useNativeDriver: true
        })
      ])
    ).start()
  }

  const onHardwareBackPress = () => {
    if (picture === undefined) {
      unmountCameraView()
      return true
    }

    setPicture(undefined)
    return true
  }

  const calculateRatio = async () => {
    let desiredRatio = '4:3'
    if (Platform.OS === 'android') {
      const ratios = await camera.current?.getSupportedRatiosAsync()
      if (ratios !== undefined) {
        const distances: Record<string, number> = {}
        const realRatios: Record<string, number> = {}
        let minDistance = null

        for (const ratio of ratios) {
          const parts = ratio.split(':')
          const realRatio = parseInt(parts[0]) / parseInt(parts[1])
          realRatios[ratio] = realRatio

          const distance = screenRatio - realRatio
          distances[ratio] = realRatio
          if (minDistance === null) {
            minDistance = ratio
          } else {
            if (distance >= 0 && distance < distances[minDistance]) {
              minDistance = ratio
            }
          }
        }
        if (minDistance !== null) {
          desiredRatio = minDistance
          const remainder = Math.floor(
            (height - realRatios[desiredRatio] * width) / 2
          )

          setImagePadding(remainder)
        }

        setRatio(desiredRatio)
        setIsRatioSet(true)
      }
    } else {
      setIsRatioSet(true)
    }
  }

  useEffect(() => {
    if (Platform.OS !== 'web' && Keyboard.isVisible()) {
      Keyboard.dismiss()
    }
  }, [])

  useEffect(() => {
    startPulseAnimation()
    if (permission?.granted === false && permission?.canAskAgain) {
      void requestPermissions()
      return
    }

    if (permission?.granted === false && !permission?.canAskAgain) {
      Toast.show({
        type: 'error',
        text1: 'Your camera permissions are disabled',
        text2: 'Please go to your device settings to enable them'
      })
      unmountCameraView()
    }
  }, [permission])

  useEffect(() => {
    BackHandler.addEventListener('hardwareBackPress', onHardwareBackPress)

    return () => {
      BackHandler.removeEventListener('hardwareBackPress', onHardwareBackPress)
    }
  }, [picture])

  return (
    <View
      style={{
        flex: 1,
        backgroundColor: 'black'
      }}
    >
      {(permission?.granted ?? false) && (
        <>
          {picture === undefined ? (
            <Camera
              ref={camera}
              onMountError={(error) => {
                console.error('Camera mount error:', error)
              }}
              onCameraReady={() => {
                if (!isRatioSet) {
                  void calculateRatio()
                }
              }}
              style={{
                flex: 1,
                marginTop: imagePadding,
                marginBottom: imagePadding
              }}
              flashMode={isFlashOn ? FlashMode.torch : FlashMode.off}
              ratio={ratio}
            />
          ) : (
            <Image
              source={{
                uri: picture.uri
              }}
              style={{
                flex: 1,
                marginTop: imagePadding,
                marginBottom: imagePadding
              }}
            />
          )}
        </>
      )}

      {!isRatioSet && (
        <Animated.View
          style={{
            opacity,
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <IconButton icon="camera" size={64} iconColor={colors.white} />
        </Animated.View>
      )}

      {picture === undefined && (
        <IconButton
          size={32}
          iconColor={colors.white}
          icon="close"
          style={{ position: 'absolute', left: 0, top }}
          onPress={() => {
            unmountCameraView()
          }}
        />
      )}

      {picture === undefined && isRatioSet && (
        <IconButton
          size={32}
          iconColor={colors.white}
          icon={isFlashOn ? 'flash' : 'flash-off'}
          style={{ position: 'absolute', right: 0, top }}
          onPress={() => {
            setIsFlashOn((prev) => !prev)
          }}
        />
      )}

      {isRatioSet && (
        <View
          style={{
            flexDirection: 'row',
            position: 'absolute',
            bottom,
            left: 0,
            right: 0
          }}
        >
          {picture === undefined ? (
            <IconButton
              icon="circle-slice-8"
              size={64}
              iconColor={colors.white}
              style={{ marginLeft: 'auto', marginRight: 'auto' }}
              onPress={async () => {
                try {
                  const picture = await camera.current?.takePictureAsync({
                    base64: false,
                    quality: 0.2,
                    skipProcessing: true
                  })
                  if (onPictureTaken !== undefined && picture !== undefined) {
                    setPicture(picture)
                  }
                } catch (error) {
                  console.error('Error taking picture:', error)
                }
              }}
            />
          ) : (
            <>
              <IconButton
                icon="close"
                iconColor={colors.white}
                style={{ marginRight: 'auto' }}
                onPress={() => {
                  setPicture(undefined)
                }}
              />

              <IconButton
                icon="send"
                iconColor={colors.white}
                containerColor={colors.orange}
                onPress={() => {
                  unmountCameraView()
                  if (onPictureTaken !== undefined) {
                    onPictureTaken(picture)
                  }
                }}
              />
            </>
          )}
        </View>
      )}
    </View>
  )
}
export default CameraView
