import { getHours, getDay } from 'date-fns'

export const showRequestRestrictions = () => {
  const now = new Date()

  if (getHours(now) >= 11 || getDay(now) === 0) {
    return true
  }

  return false
}
