// React
import { useEffect, useState } from 'react'
// Expo
import { loadAsync } from 'expo-font'
import * as SplashScreen from 'expo-splash-screen'

const useAssets = () => {
  const [fontsLoaded, setFontsLoaded] = useState(false)

  const loadFonts = async () => {
    await loadAsync({
      'Maersk-Regular': require('@assets/fonts/MaerskText-Regular.ttf'),
      'Maersk-Light': require('@assets/fonts/MaerskText-Light.ttf'),
      'Maersk-Bold': require('@assets/fonts/MaerskText-Bold.ttf')
    })
    await SplashScreen.hideAsync()
    setFontsLoaded(true)
  }

  useEffect(() => {
    void loadFonts()
  }, [])

  return fontsLoaded
}
export default useAssets
