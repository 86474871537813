// External
import { signOut } from 'firebase/auth'
import OneSignal from 'react-native-onesignal'
import { Platform } from 'react-native'
// Config
import { auth } from '@/config/firebase'
// Services
import { getUser, updateUser } from '@/services/user.service'
// Stores
import useUnsubscribeStore from '@/store/useUnsubscribeStore'
import useAppStore from '@/store/useAppStore'

export const logOut = async () => {
  try {
    if (auth.currentUser !== null && Platform.OS !== 'web') {
      const res = await getUser(auth.currentUser.uid)

      if (res !== undefined) {
        const deviceState = await OneSignal.getDeviceState()

        if (deviceState?.userId != null) {
          const { userId } = deviceState
          const { playerIDs } = res.user

          // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
          if (playerIDs !== undefined && playerIDs.includes(userId)) {
            await updateUser({
              fields: { playerIDs: playerIDs.filter((id) => id !== userId) },
              collection: res.isPending ? 'pendingUsers' : 'users'
            })
          }
        }
      }
    }

    useUnsubscribeStore.getState().unsubscribeAll()
    useAppStore.getState().setIsLoggedIn(false)
    useAppStore.getState().setUser(undefined)

    void signOut(auth)
  } catch (error) {
    console.error('Error logging out: ', error)
  }
}
