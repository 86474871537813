// External
import AsyncStorage from '@react-native-async-storage/async-storage'
import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'
// Models
import { type User } from '@/models'

interface AppState {
  showWelcomeScreen: boolean
  setShowWelcomeScreen: (value: boolean) => void
  showNotificationsScreen: boolean
  setShowNotificationsScreen: (value: boolean) => void
  user: User | undefined
  setUser: (user: User | undefined) => void
  isLoggedIn: boolean
  setIsLoggedIn: (value: boolean) => void
  unreadNotifications: number
  setUnreadNotifications: (value: number) => void
}

const useAppStore = create<AppState>()(
  persist(
    (set) => ({
      showWelcomeScreen: true,
      setShowWelcomeScreen: (value) => {
        set({ showWelcomeScreen: value })
      },
      showNotificationsScreen: true,
      setShowNotificationsScreen: (value) => {
        set({ showNotificationsScreen: value })
      },
      user: undefined,
      setUser: (user) => {
        set({ user })
      },
      isLoggedIn: true,
      setIsLoggedIn: (value) => {
        set({ isLoggedIn: value })
      },
      unreadNotifications: 0,
      setUnreadNotifications: (value) => {
        set({ unreadNotifications: value })
      }
    }),
    {
      name: 'app-storage',
      // AsyncStorage works both on mobile & web => https://docs.expo.dev/versions/latest/sdk/async-storage/
      storage: createJSONStorage(() => AsyncStorage)
    }
  )
)
export default useAppStore
