import type { CameraCapturedPicture } from 'expo-camera'
import type { DocumentPickerAsset } from 'expo-document-picker'
import type { ImagePickerAsset } from 'expo-image-picker'

export const getFilename = (
  file: CameraCapturedPicture | DocumentPickerAsset | ImagePickerAsset
) => {
  if ('name' in file) {
    return file.name
  }

  return file.uri.substring(file.uri.lastIndexOf('/') + 1)
}
