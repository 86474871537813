// External
import { StyleSheet, View } from 'react-native'
import Constants from 'expo-constants'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
// Components
import { Text } from './Text'
// Constants
import { colors } from '@/constants'

export const DevIndicator = () => {
  const { bottom } = useSafeAreaInsets()

  if (
    Constants.expoConfig?.extra !== undefined &&
    Constants.expoConfig.extra.mode === 'development'
  ) {
    return (
      <View style={styles.container}>
        <Text
          style={{
            color: colors.white,
            marginBottom: bottom > 16 ? 16 : 0
          }}
          variant="smallBold"
        >
          DEVELOPMENT MODE
        </Text>
      </View>
    )
  }

  return null
}

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    bottom: 0,
    paddingVertical: 4,
    width: '100%',
    backgroundColor: colors.denied,
    alignItems: 'center'
  }
})
