// External
import { useState } from 'react'
import { View, Alert } from 'react-native'
import { Avatar, IconButton, ActivityIndicator } from 'react-native-paper'
import { SheetManager } from 'react-native-actions-sheet'
import type { CameraCapturedPicture } from 'expo-camera'
import type { ImagePickerAsset } from 'expo-image-picker'
import Toast from 'react-native-toast-message'
// Components
import { Link, Text, Version } from '@/components/common'
import { InfoField } from '@/components/profile'
import { DeleteAccount, EditPassword, EditPhone } from '@/components/modal'
// Constants
import { colors } from '@/constants'
// Config
import { auth } from '@/config/firebase'
// Store
import useAppStore from '@/store/useAppStore'
import usePortalStore from '@/store/usePortalStore'
// Layouts
import { SafeArea, KeyboardFix } from '@/layouts'
// Use case
import { editAvatar } from '@/useCases/user.use-case'
// Utils
import { logOut, getInitials } from '@/utils'
import { UseCaseError } from '@/models'

const Profile = () => {
  const [uploadingAvatar, setUploadingAvatar] = useState(false)
  const user = useAppStore((state) => state.user)
  const showModal = usePortalStore((state) => state.showModal)

  if (user === undefined) {
    return null
  }

  const userPhoneNumber = `+${user.phoneNumber.prefix} ${user.phoneNumber.number}`

  const onAvatar = async (avatar: CameraCapturedPicture | ImagePickerAsset) => {
    try {
      if (auth.currentUser !== null) {
        setUploadingAvatar(true)
        await editAvatar({ avatar, id: auth.currentUser.uid })
        Toast.show({ type: 'success', text1: 'Avatar edited successfully' })
        setUploadingAvatar(false)
      }
    } catch (error) {
      if (error instanceof UseCaseError) {
        console.error(error)
        Toast.show({
          type: 'error',
          text1: error.title,
          text2: error.message
        })
        setUploadingAvatar(false)
      }
    }
  }

  return (
    <SafeArea disabledInsets={['bottom']}>
      <Text variant="h2Bold" style={{ textAlign: 'center' }}>
        Profile
      </Text>

      <KeyboardFix contentContainerStyle={{ paddingHorizontal: 24 }}>
        <View style={{ marginTop: 40, marginBottom: 24, alignItems: 'center' }}>
          {user.profilePhoto !== undefined ? (
            <Avatar.Image source={{ uri: user.profilePhoto }} />
          ) : (
            <Avatar.Text
              labelStyle={{ color: colors.white, fontFamily: 'Maersk-Bold' }}
              label={getInitials({
                firstName: user.firstName,
                lastName: user.lastName
              })}
            />
          )}

          <View
            style={{
              flexDirection: 'row',
              marginTop: 8
            }}
          >
            {uploadingAvatar && (
              <ActivityIndicator
                color={colors.orange}
                size={14}
                style={{ marginRight: 4 }}
              />
            )}

            <Link
              text={uploadingAvatar ? 'Editing avatar' : 'Edit avatar'}
              disabled={uploadingAvatar}
              onPress={() => {
                if (!__DEV__) {
                  Alert.alert('Currently not available for testing')
                  return
                }

                void SheetManager.show('edit-avatar', {
                  payload: {
                    onImageSelected: onAvatar,
                    onPictureTaken: onAvatar
                  }
                })
              }}
            />
          </View>
        </View>

        <Text variant="mediumBold" style={{ marginBottom: 16 }}>
          Information
        </Text>

        <InfoField
          label="Name"
          name="firstName"
          value={user.firstName}
          editable
        />

        <InfoField
          label="Last Name"
          name="lastName"
          value={user.lastName}
          editable
        />

        <InfoField
          label="Phone Number"
          value={userPhoneNumber}
          linkOnPress={() => {
            showModal({
              content: <EditPhone currentPhoneNumber={user.phoneNumber} />
            })
          }}
          linkText="Edit"
        />

        <InfoField label="Waterfront" value={user.waterfront} />

        <InfoField label="Seniority" value={user.seniority} />

        <InfoField label="Category" value={user.category} />

        <InfoField label="List" value={user.list} />

        <InfoField label="Email" value={user.email} />

        <InfoField
          label="Password"
          value="●●●●●●●●"
          linkOnPress={() => {
            showModal({ content: <EditPassword /> })
          }}
          linkText="Edit"
        />

        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 40
          }}
        >
          <IconButton
            icon="logout-variant"
            iconColor={colors.orange}
            onPress={logOut}
            style={{ marginLeft: -12 }}
          />

          <Link
            text="Log out"
            color={colors.primaryDarkGray}
            onPress={() => {
              void logOut()
            }}
            variant="link"
          />
        </View>

        <Link
          text="Delete my account"
          style={{ alignSelf: 'center', marginTop: 40, marginBottom: 32 }}
          variant="smallLinkRegular"
          color={colors.neutralGray}
          onPress={() => {
            showModal({ content: <DeleteAccount /> })
          }}
        />

        <Version style={{ marginBottom: 16 }} />
      </KeyboardFix>
    </SafeArea>
  )
}
export default Profile
