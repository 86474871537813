import { isWithinInterval } from 'date-fns'

interface Params {
  rangeStart: Date
  rangeEnd: Date
  dateFrom: Date
  dateTo: Date
}

export const areDatesInRange = ({
  rangeStart,
  rangeEnd,
  dateFrom,
  dateTo
}: Params) => {
  return (
    isWithinInterval(dateFrom, { start: rangeStart, end: rangeEnd }) &&
    isWithinInterval(dateTo, { start: rangeStart, end: rangeEnd })
  )
}
