// External
import { type CompositeScreenProps } from '@react-navigation/native'
import { type BottomTabScreenProps } from '@react-navigation/bottom-tabs'
import { type StackScreenProps } from '@react-navigation/stack'
import { useEffect, useState } from 'react'
import { isAfter, isSameDay, isBefore } from 'date-fns'
import { ScrollView, Dimensions } from 'react-native'
// Components
import { Button } from '@/components/common'
import { CustomCalendar, RestrictionWarnings } from '@/components/calendar'
// Layouts
import { SafeArea } from '@/layouts'
import { type CalendarStackParamList } from '@/navigators/CalendarStack'
// Models
import { type HomeTabsParamList } from '@/navigators/HomeTabs'
// Utils
import {
  generateValidCalendarRange,
  showRequestRestrictions,
  generateMarkedDates
} from '@/utils'

type Props = CompositeScreenProps<
  StackScreenProps<CalendarStackParamList, 'Calendar'>,
  BottomTabScreenProps<HomeTabsParamList>
>

const Calendar = ({ navigation }: Props) => {
  // Splitting into two states that are strings prevents rerendering every second even if the date is the same
  const [minDate, setMinDate] = useState(
    generateValidCalendarRange().minDateString
  )
  const [maxDate] = useState(generateValidCalendarRange().maxDateString)
  const [dateFrom, setDateFrom] = useState<Date | undefined>()
  const [dateTo, setDateTo] = useState<Date | undefined>()

  const markedDates = generateMarkedDates(dateFrom, dateTo)

  useEffect(() => {
    const interval = setInterval(() => {
      setMinDate(generateValidCalendarRange().minDateString)
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  return (
    <SafeArea
      disabledInsets={['top', 'bottom']}
      style={{
        marginTop: Dimensions.get('window').height < 600 ? 20 : 40,
        marginBottom: 16,
        marginHorizontal: 24
      }}
    >
      {showRequestRestrictions() && <RestrictionWarnings />}

      <ScrollView style={{ flex: 1 }} nestedScrollEnabled>
        <CustomCalendar
          style={{ marginBottom: 80 }}
          minDate={minDate}
          maxDate={maxDate}
          onDayPress={(date) => {
            const selectedDate = new Date(date.year, date.month - 1, date.day)

            if (dateFrom === undefined && dateTo === undefined) {
              setDateFrom(selectedDate)
              setDateTo(selectedDate)
              return
            }

            if (dateFrom !== undefined && dateTo !== undefined) {
              if (
                isSameDay(selectedDate, dateFrom) ||
                isSameDay(selectedDate, dateTo)
              ) {
                setDateTo(selectedDate)
                setDateFrom(selectedDate)
                return
              }

              if (isAfter(selectedDate, dateTo)) {
                setDateTo(selectedDate)
                return
              }

              if (
                isBefore(selectedDate, dateTo) &&
                isAfter(selectedDate, dateFrom)
              ) {
                setDateTo(selectedDate)
                return
              }

              if (
                isBefore(selectedDate, dateFrom) &&
                isBefore(selectedDate, dateTo)
              ) {
                setDateFrom(selectedDate)
              }
            }
          }}
          {...(dateFrom !== undefined &&
            dateTo !== undefined &&
            !isSameDay(dateFrom, dateTo) && {
              markingType: 'period'
            })}
          markedDates={markedDates}
        />
      </ScrollView>

      <Button
        disabled={dateFrom === undefined && dateTo === undefined}
        onPress={() => {
          if (dateFrom !== undefined && dateTo !== undefined) {
            navigation.navigate('RequestADayOff', {
              dateFrom,
              dateTo
            })
          }
        }}
      >
        Request a day off
      </Button>
    </SafeArea>
  )
}
export default Calendar
