import { type ReactNode } from 'react'
import { create } from 'zustand'
import { type CameraCapturedPicture } from 'expo-camera'

type PictureFunction = (picture: CameraCapturedPicture) => void

interface PortalState {
  modalVisible: boolean
  modalDismissable: boolean
  modalChildren: ReactNode
  modalShowCloseButton: boolean
  showModal: ({
    dismissable,
    content,
    hideCloseButton
  }: {
    dismissable?: boolean
    content: ReactNode
    hideCloseButton?: boolean
  }) => void
  hideModal: () => void
  cameraViewMounted: boolean
  mountCameraView: (onPictureTaken: PictureFunction) => void
  unmountCameraView: () => void
  onPictureTaken: PictureFunction | undefined
}

const usePortalStore = create<PortalState>()((set) => ({
  modalVisible: false,
  modalDismissable: false,
  modalChildren: null,
  modalShowCloseButton: true,
  showModal: ({ dismissable = true, content, hideCloseButton = false }) => {
    set({
      modalVisible: true,
      modalDismissable: dismissable,
      modalChildren: content,
      modalShowCloseButton: !hideCloseButton
    })
  },
  hideModal: () => {
    set({
      modalVisible: false,
      modalDismissable: true,
      modalShowCloseButton: true
    })
  },
  cameraViewMounted: false,
  mountCameraView: (onPictureTaken) => {
    set({ cameraViewMounted: true, onPictureTaken })
  },
  unmountCameraView: () => {
    set({ cameraViewMounted: false, onPictureTaken: undefined })
  },
  onPictureTaken: undefined
}))
export default usePortalStore
