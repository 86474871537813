// External
import { Formik } from 'formik'
import { object, string, ref, type InferType } from 'yup'
import { type StackScreenProps } from '@react-navigation/stack'
import Toast from 'react-native-toast-message'
// Components
import { Input, Button } from '@/components/common'
import { Steps } from '@/components/signup'
// Constants
import { signupSteps } from '@/constants'
// Layouts
import { SafeArea, KeyboardFix } from '@/layouts'
// Models
import { type PreHomeStackParamList } from '@/navigators/PreHomeStack'
import { UseCaseError } from '@/models/use-case-error.class'
// Use cases
import { signUp } from '@/useCases/user.use-case'

type Props = StackScreenProps<PreHomeStackParamList, 'SignupStep3'>

const SignupStep3 = ({ route }: Props) => {
  const signupStep3Schema = object({
    password: string()
      .min(8, 'Password is too short')
      .required('Password is required'),
    repeatPassword: string()
      .oneOf([ref('password')], 'Passwords must match')
      .required('Please re enter your password')
  })

  type SignupStep3SchemaType = InferType<typeof signupStep3Schema>

  return (
    <SafeArea disabledInsets={['top']}>
      <Formik
        initialValues={{
          password: '',
          repeatPassword: ''
        }}
        validationSchema={signupStep3Schema}
        onSubmit={async ({ password }) => {
          try {
            await signUp({
              ...route.params,
              password
            })
          } catch (error) {
            if (error instanceof UseCaseError) {
              Toast.show({
                type: 'error',
                text1: error.title,
                text2: error.message
              })
            }
          }
        }}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          values,
          touched,
          errors,
          isValid,
          isSubmitting
        }) => (
          <>
            <Steps
              currentStep={2}
              steps={signupSteps}
              style={{ marginTop: 40, marginHorizontal: 24 }}
            />

            <KeyboardFix contentContainerStyle={{ paddingHorizontal: 24 }}>
              <Input<SignupStep3SchemaType>
                name="password"
                textLabel="Password (min. 8 characters)"
                onChangeText={handleChange('password')}
                onBlur={handleBlur('waterfront')}
                value={values.password}
                autoCapitalize="none"
                secureTextEntry
                touched={touched}
                errors={errors}
              />

              <Input<SignupStep3SchemaType>
                name="repeatPassword"
                textLabel="Repeat the password"
                onChangeText={handleChange('repeatPassword')}
                onBlur={handleBlur('repeatPassword')}
                value={values.repeatPassword}
                autoCapitalize="none"
                secureTextEntry
                touched={touched}
                errors={errors}
              />

              <Button
                style={{ marginTop: 24, marginBottom: 80 }}
                onPress={() => {
                  handleSubmit()
                }}
                disabled={!isValid || isSubmitting}
                loading={isSubmitting}
              >
                Create account
              </Button>
            </KeyboardFix>
          </>
        )}
      </Formik>
    </SafeArea>
  )
}
export default SignupStep3
