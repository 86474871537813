// External
import { View } from 'react-native'
import { Divider } from 'react-native-paper'
// Components
import { Text } from '@/components/common'
// Constants
import { colors } from '@/constants'
// Models
import {
  type Notification as NotificationType,
  type NotificationWithID as NotificationWithIDType
} from '@/models/notification.interface'

interface Props {
  notification: NotificationType | NotificationWithIDType
}

export const NotificationSummary = ({ notification }: Props) => {
  return (
    <View>
      <Text
        variant="mediumBold"
        color={colors.blue}
        style={{ marginBottom: 8 }}
      >
        {notification.title}
      </Text>

      <Text style={{ marginBottom: 8 }}>{notification.subtitle}</Text>

      <Text
        variant="smallBold"
        color={colors.orange}
        style={{ marginBottom: 12 }}
      >
        {notification.date.toDate().toDateString()}
      </Text>

      <Divider />
    </View>
  )
}
