// External
import { View, Pressable, StyleSheet, type ViewStyle } from 'react-native'
import { format } from 'date-fns'
// Components
import { Text } from './Text'
// Constants
import { colors } from '@/constants'
// Models
import { type RequestWithID } from '@/models/request.interface'

interface Props {
  request: RequestWithID
  size: 'regular' | 'small'
  onPress?: () => void
  style?: ViewStyle
}

export const RequestCard = ({ request, size, onPress, style }: Props) => {
  const styles = StyleSheet.create({
    container: {
      borderRadius: 4,
      borderColor: colors.lightGray,
      borderWidth: 1,
      paddingRight: 16,
      flexDirection: 'row',
      height: size === 'regular' ? 128 : 88
    },
    colorStripe: {
      width: 8,
      height: '100%',
      backgroundColor: colors[request.status]
    },
    title: {
      marginTop: 12,
      marginBottom: 20
    },
    status: {
      marginLeft: 'auto',
      width: size === 'regular' ? 128 : 88,
      transform: [
        { rotate: '270deg' },
        { translateY: size === 'regular' ? 104 : 72 }
      ]
    },
    statusText: {
      textAlign: 'center',
      textTransform: size === 'regular' ? 'uppercase' : 'capitalize'
    },
    dates: {
      ...(size === 'small' && {
        flexDirection: 'row',
        gap: 16
      })
    }
  })
  const titleTextVariant = size === 'regular' ? 'mediumBold' : 'baseBold'
  const dateTextVariant = size === 'regular' ? 'baseBold' : 'smallBold'
  const innerDateTextVariant =
    size === 'regular' ? 'baseRegular' : 'smallRegular'
  const statusTextVariant = size === 'regular' ? 'h2Bold' : 'baseBold'

  return (
    <Pressable style={[styles.container, style]} onPress={onPress}>
      <View style={styles.colorStripe} />

      <View style={{ marginLeft: 16 }}>
        <Text variant={titleTextVariant} style={styles.title}>
          Time off request
        </Text>

        <View style={styles.dates}>
          <Text variant={dateTextVariant} style={{ marginBottom: 16 }}>
            From:{' '}
            <Text variant={innerDateTextVariant}>
              {format(request.dateFrom.toDate(), 'MMMM do, yyyy')}
            </Text>
          </Text>

          <Text variant={dateTextVariant}>
            Until:{' '}
            <Text variant={innerDateTextVariant}>
              {format(request.dateTo.toDate(), 'MMMM do, yyyy')}
            </Text>
          </Text>
        </View>
      </View>

      <View style={styles.status}>
        <Text
          variant={statusTextVariant}
          color={colors[request.status]}
          style={styles.statusText}
        >
          {request.status}
        </Text>
      </View>
    </Pressable>
  )
}
