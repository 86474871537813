// External
import {
  createUserWithEmailAndPassword,
  sendPasswordResetEmail as sendFirebasePasswordResetEmail,
  updatePassword as updateFirebasePassword
} from 'firebase/auth'
import { getDoc, setDoc, doc, updateDoc } from 'firebase/firestore'
import { getFunctions, httpsCallable } from 'firebase/functions'
// Config
import { auth, db } from '@/config/firebase'
// Models
import type { PendingUser, PendingUserUpdate, User, UserUpdate } from '@/models'

export const createUser = async ({
  email,
  password
}: {
  email: string
  password: string
}) => {
  try {
    await createUserWithEmailAndPassword(auth, email, password)
  } catch (error) {
    console.error('Error creating user with email and password:', error)
    return await Promise.reject(error)
  }
}

export const getUser = async (id: string) => {
  try {
    const pendingUserRef = doc(db, 'pendingUsers', id)
    const pendingUserSnapshot = await getDoc(pendingUserRef)
    if (pendingUserSnapshot.exists()) {
      return {
        user: pendingUserSnapshot.data() as PendingUser,
        isPending: true
      }
    }

    const userRef = doc(db, 'users', id)
    const userSnapshot = await getDoc(userRef)
    if (userSnapshot.exists()) {
      return { user: userSnapshot.data() as User, isPending: false }
    }
  } catch (error) {
    console.error('Error getting user:', error)
    return await Promise.reject(error)
  }
}

export const updateUser = async ({
  fields,
  collection
}: {
  fields: UserUpdate | PendingUserUpdate
  collection: 'users' | 'pendingUsers'
}) => {
  try {
    if (auth.currentUser !== null) {
      const requestRef = doc(db, collection, auth.currentUser.uid)
      await updateDoc(requestRef, fields)
    }
  } catch (error) {
    console.error(
      `Error updating user in collection ${collection} with fields ${JSON.stringify(
        fields
      )}:`,
      error
    )
    return await Promise.reject(error)
  }
}

export const createUserInFirestore = async ({
  email,
  firstName,
  lastName,
  phoneNumber,
  seniority,
  waterfront,
  createdAt,
  appVersion,
  isPushEnabled,
  playerIDs
}: PendingUser) => {
  try {
    if (auth.currentUser !== null) {
      const docRef = doc(db, `pendingUsers/${auth.currentUser?.uid}`)
      await setDoc(docRef, {
        firstName,
        lastName,
        phoneNumber,
        waterfront,
        seniority,
        email,
        createdAt,
        appVersion,
        isPushEnabled,
        playerIDs
      })
    }
  } catch (error) {
    console.error('Error creating user in Firestore:', error)
    return await Promise.reject(error)
  }
}

export const updatePassword = async (password: string) => {
  try {
    if (auth.currentUser !== null) {
      await updateFirebasePassword(auth.currentUser, password)
    }
  } catch (error) {
    console.error('Error updating password:', error)
    return await Promise.reject(error)
  }
}

export const sendPasswordResetEmail = async (email: string) => {
  try {
    await sendFirebasePasswordResetEmail(auth, email)
  } catch (error) {
    console.error('Error resetting password:', error)
    return await Promise.reject(error)
  }
}

export const deleteAccount = async (id: string) => {
  try {
    const functions = getFunctions()
    const callableRef = httpsCallable(functions, 'users-functions-deleteUser')
    await callableRef({ uid: id })
  } catch (error) {
    console.error('Error deleting account:', error)
    return await Promise.reject(error)
  }
}
