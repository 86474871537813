import 'react-native-gesture-handler'
// External
import React from 'react'
import { Platform, useWindowDimensions } from 'react-native'
import { NavigationContainer } from '@react-navigation/native'
import { StatusBar } from 'expo-status-bar'
import * as SplashScreen from 'expo-splash-screen'
import * as Sentry from '@sentry/react-native'
import {
  Provider as PaperProvider,
  Portal,
  Modal,
  IconButton
} from 'react-native-paper'
import Toast from 'react-native-toast-message'
import { SafeAreaProvider } from 'react-native-safe-area-context'
import { SheetProvider } from 'react-native-actions-sheet'
// Components
import '@/components/actionSheet/sheets'
import { DevIndicator } from '@/components/common'
// Config
import { theme } from '@/config/theme-md3'
import { linking } from '@/config/linking'
// Constants
import { colors } from '@/constants'
// Hooks
import useAssets from '@/hooks/useAssets'
import useUser from '@/hooks/useUser'
import useOneSignal from '@/hooks/useOneSignal'
import useEASUpdate from '@/hooks/useEASUpdate'
import useCheckAppVersion from '@/hooks/useCheckAppVersion'
import useIsConnected from '@/hooks/useIsConnected'
// Stores
import usePortalStore from '@/store/usePortalStore'
import useAppStore from '@/store/useAppStore'
// Navigators
import PreHomeStack from '@/navigators/PreHomeStack'
import HomeTabs from '@/navigators/HomeTabs'
// Views
import CameraView from '@/views/Common/CameraView'
import NoConnection from '@/views/NoConnection'
// Layouts
import { ErrorBoundary } from '@/wrappers'

void SplashScreen.preventAutoHideAsync()
if (!__DEV__) {
  Sentry.init({
    environment: 'production',
    dsn: 'https://fc0a0e2022c4054af9b708f564be07b1@o4505713690935296.ingest.sentry.io/4506422149513216'
  })
}

const isMobile = Platform.OS === 'android' || Platform.OS === 'ios'

const App = () => {
  useEASUpdate()
  useCheckAppVersion()
  useOneSignal()
  const fontsLoaded = useAssets()
  useUser()
  const { isLoggedIn, user } = useAppStore((state) => ({
    isLoggedIn: state.isLoggedIn,
    user: state.user
  }))
  const {
    modalVisible,
    modalDismissable,
    modalChildren,
    hideModal,
    modalShowCloseButton,
    cameraViewMounted
  } = usePortalStore((state) => ({
    modalVisible: state.modalVisible,
    modalDismissable: state.modalDismissable,
    modalChildren: state.modalChildren,
    hideModal: state.hideModal,
    modalShowCloseButton: state.modalShowCloseButton,
    cameraViewMounted: state.cameraViewMounted
  }))
  const isConnected = useIsConnected()
  const dimensions = useWindowDimensions()

  if (!fontsLoaded) {
    return null
  }

  return (
    <>
      <SafeAreaProvider
        {...(Platform.OS === 'web' && {
          style: {
            flex: 1,
            borderLeftWidth: 1,
            borderRightWidth: 1,
            borderColor: colors.lightGray,
            ...(dimensions.width > 1280 && { marginHorizontal: '10%' })
          }
        })}
      >
        <StatusBar style="auto" />

        <PaperProvider theme={theme}>
          <ErrorBoundary>
            <Portal>
              {cameraViewMounted && <CameraView />}

              <Modal
                visible={modalVisible}
                dismissable={modalDismissable ?? true}
                onDismiss={hideModal}
                contentContainerStyle={{
                  backgroundColor: colors.white,
                  marginHorizontal: 24,
                  paddingTop: 12,
                  paddingBottom: 48,
                  paddingHorizontal: 16
                }}
              >
                {modalShowCloseButton && (
                  <IconButton
                    icon="close"
                    onPress={hideModal}
                    style={{ marginLeft: 'auto', marginBottom: 16 }}
                  />
                )}

                {modalChildren}
              </Modal>

              <DevIndicator />
            </Portal>

            <SheetProvider>
              {isConnected ?? false ? (
                <NavigationContainer theme={theme} linking={linking}>
                  {isLoggedIn && user !== undefined ? (
                    <HomeTabs />
                  ) : (
                    <PreHomeStack />
                  )}
                </NavigationContainer>
              ) : (
                <NoConnection />
              )}
            </SheetProvider>
          </ErrorBoundary>
        </PaperProvider>
      </SafeAreaProvider>

      <Toast position={isMobile ? 'bottom' : 'top'} />
    </>
  )
}

export default App
