// External
import { IconButton } from 'react-native-paper'
import Toast from 'react-native-toast-message'
// Components
import { Text, Button } from '@/components/common'
// Config
import { auth } from '@/config/firebase'
// Constants
import { colors } from '@/constants'
// Layouts
import { SafeArea } from '@/layouts'
// Store
import useAppStore from '@/store/useAppStore'

interface Props {
  resetError: () => void
}

const ErrorView = ({ resetError }: Props) => {
  const { isLoggedIn, setIsLoggedIn, user, setUser } = useAppStore((state) => ({
    isLoggedIn: state.isLoggedIn,
    setIsLoggedIn: state.setIsLoggedIn,
    user: state.user,
    setUser: state.setUser
  }))

  return (
    <SafeArea style={{ marginHorizontal: 24 }}>
      <IconButton
        iconColor={colors.denied}
        size={64}
        icon="alert-outline"
        style={{ alignSelf: 'center', marginTop: 'auto' }}
      />

      <Text
        style={{ marginBottom: 24, textAlign: 'center' }}
        variant="mediumBold"
        color={colors.blue}
      >
        Something went wrong
      </Text>

      <Text style={{ textAlign: 'center' }}>
        An unknown error has occurred, please reload the application using the
        button below. If the error persists, please get in touch with us
      </Text>

      <Button
        style={{ marginTop: 'auto', marginBottom: 120 }}
        icon="refresh"
        onPress={() => {
          // This is just in case there's no session but the user data wasn't cleared in the auth state change listener due to whatever reason
          if (auth.currentUser === null && (isLoggedIn || user !== undefined)) {
            setIsLoggedIn(false)
            setUser(undefined)
            Toast.show({
              type: 'error',
              text1: 'You have been logged out due to an error',
              text2: 'Please log in again'
            })
          }

          resetError()
        }}
      >
        Reload application
      </Button>
    </SafeArea>
  )
}
export default ErrorView
