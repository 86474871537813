// External
import { Formik } from 'formik'
import { object, string, type InferType } from 'yup'
import { type StackScreenProps } from '@react-navigation/stack'
// Layouts
import { SafeArea, KeyboardFix } from '@/layouts'
// Types
import { type PreHomeStackParamList } from '@/navigators/PreHomeStack'
// Components
import { Input, Button } from '@/components/common'
import { Steps } from '@/components/signup'
// Constants
import { waterfrontRegEx, seniorityRegEx, signupSteps } from '@/constants'

type Props = StackScreenProps<PreHomeStackParamList, 'SignupStep2'>

const SignupStep2 = ({ navigation, route }: Props) => {
  const signupStep2Schema = object({
    waterfront: string()
      .matches(waterfrontRegEx, 'Waterfront number is invalid')
      .required('Waterfront is required'),
    seniority: string()
      .matches(seniorityRegEx, 'Please enter a single uppercase letter')
      .required('Seniority is required'),
    email: string()
      .email('Please enter a valid email')
      .required('Email is required')
      .test(
        'uppercase-check',
        'Email cannot contain uppercase letters',
        (value) => value === value?.toLowerCase()
      )
  })

  type SignupStep2SchemaType = InferType<typeof signupStep2Schema>

  return (
    <SafeArea disabledInsets={['top']}>
      <Formik
        initialValues={{
          waterfront: '',
          seniority: '',
          email: ''
        }}
        validationSchema={signupStep2Schema}
        onSubmit={({ waterfront, seniority, email }) => {
          navigation.navigate('SignupStep3', {
            ...route.params,
            waterfront,
            seniority,
            email
          })
        }}
      >
        {({
          handleChange,
          setFieldValue,
          handleBlur,
          handleSubmit,
          values,
          touched,
          errors,
          isValid
        }) => (
          <>
            <Steps
              currentStep={1}
              steps={signupSteps}
              style={{ marginTop: 40, marginHorizontal: 24 }}
            />

            <KeyboardFix contentContainerStyle={{ paddingHorizontal: 24 }}>
              <Input<SignupStep2SchemaType>
                name="waterfront"
                textLabel="Waterfront number"
                placeholder="XXXXX"
                onChangeText={handleChange('waterfront')}
                onBlur={handleBlur('waterfront')}
                value={values.waterfront}
                maxLength={5}
                inputMode="numeric"
                touched={touched}
                errors={errors}
              />

              <Input<SignupStep2SchemaType>
                name="seniority"
                textLabel="Seniority"
                onChangeText={handleChange('seniority')}
                onBlur={handleBlur('seniority')}
                value={values.seniority}
                maxLength={1}
                autoCapitalize="characters"
                touched={touched}
                errors={errors}
              />

              <Input<SignupStep2SchemaType>
                name="email"
                textLabel="Email"
                onChangeText={handleChange('email')}
                onBlur={handleBlur('email')}
                value={values.email}
                inputMode="email"
                autoComplete="email"
                autoCapitalize="none"
                touched={touched}
                errors={errors}
              />

              <Button
                style={{ marginTop: 24, marginBottom: 80 }}
                onPress={() => {
                  handleSubmit()
                }}
                disabled={!isValid}
              >
                Next step
              </Button>
            </KeyboardFix>
          </>
        )}
      </Formik>
    </SafeArea>
  )
}
export default SignupStep2
