// External
import Constants from 'expo-constants'
import { type TextStyle } from 'react-native'
// Components
import { Text } from './Text'
// Constants
import { colors } from '@/constants'

interface Props {
  style?: TextStyle
}

export const Version = ({ style = {} }: Props) => {
  return (
    <Text
      style={[{ textAlign: 'center' }, style]}
      variant="smallRegular"
      color={colors.neutralGray}
    >
      Version: {Constants.expoConfig?.version}
    </Text>
  )
}
