import { Path, Svg } from 'react-native-svg'
import { BaseButton } from './BaseButton'
import { StyleSheet, View } from 'react-native'
import { useTheme, type ButtonProps } from 'react-native-paper'

const FancyFeature = () => {
  return (
    <Svg
      width={53}
      height={27}
      viewBox="0 0 53 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        position: 'absolute',
        top: -12,
        left: -14,
        zIndex: 1
      }}
    >
      <Path fill="#fff" d="M1 2.356h52v24H1z" />
      <Path
        d="M25 12.453L0 22.638v1.62l25-10.185v-1.62zM4 17.736L46 .625v1.62L4 19.355v-1.62z"
        fill="#FF6441"
      />
    </Svg>
  )
}

export const FancyButton = ({
  children,
  style,
  ...otherProps
}: ButtonProps) => {
  const { colors } = useTheme()

  const fancyButtonStyle = StyleSheet.compose(
    {
      borderWidth: 1.5
    },
    style
  )

  return (
    <View>
      <FancyFeature />
      <BaseButton
        mode="outlined"
        theme={{
          colors: {
            primary: 'white' // Prevent change the color when is pressed
          }
        }}
        labelStyle={{
          color: colors.primary // If primary color is white the text is not visible
        }}
        style={fancyButtonStyle}
        {...otherProps}
      >
        {children}
      </BaseButton>
    </View>
  )
}
