// External
import { Formik } from 'formik'
import { object, string, type InferType } from 'yup'
import { type StackScreenProps } from '@react-navigation/stack'
import { type PreHomeStackParamList } from '@/navigators/PreHomeStack'
// Components
import { Button, Input, TextAndLink, DropdownSelect } from '@/components/common'
import { Steps } from '@/components/signup'
// Constants
import {
  phoneNumberRegEx,
  signupSteps,
  countryPrefixOptions
} from '@/constants'
// Layouts
import { SafeArea, KeyboardFix } from '@/layouts'

type Props = StackScreenProps<PreHomeStackParamList, 'SignupStep1'>

const SignupStep1 = ({ navigation }: Props) => {
  const signupStep1Schema = object({
    firstName: string().required('First name is required'),
    lastName: string().required('Last name is required'),
    phoneNumber: string()
      .matches(phoneNumberRegEx.number, 'Phone number is too short')
      .required('Cell phone number is required')
  })

  type SignupStep1SchemaType = InferType<typeof signupStep1Schema>

  return (
    <SafeArea disabledInsets={['top']}>
      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          phonePrefix: 1,
          phoneNumber: ''
        }}
        validationSchema={signupStep1Schema}
        onSubmit={({ firstName, lastName, phonePrefix, phoneNumber }) => {
          navigation.navigate('SignupStep2', {
            firstName,
            lastName,
            phoneNumber: {
              prefix: phonePrefix,
              number: parseInt(phoneNumber)
            }
          })
        }}
      >
        {({
          handleChange,
          setFieldValue,
          handleBlur,
          handleSubmit,
          values,
          touched,
          errors,
          isValid
        }) => (
          <>
            <Steps
              currentStep={0}
              steps={signupSteps}
              style={{ marginTop: 40, marginHorizontal: 24 }}
            />

            <KeyboardFix contentContainerStyle={{ paddingHorizontal: 24 }}>
              <Input<SignupStep1SchemaType>
                name="firstName"
                textLabel="First name"
                onChangeText={handleChange('firstName')}
                onBlur={handleBlur('firstName')}
                value={values.firstName}
                autoComplete="name"
                touched={touched}
                errors={errors}
              />

              <Input<SignupStep1SchemaType>
                name="lastName"
                textLabel="Last name"
                onChangeText={handleChange('lastName')}
                onBlur={handleBlur('lastName')}
                value={values.lastName}
                autoComplete="name-family"
                touched={touched}
                errors={errors}
              />

              <DropdownSelect
                style={{ marginBottom: 24 }}
                textLabel="Cell phone number prefix"
                options={countryPrefixOptions}
                onSelect={({ value }) => {
                  setFieldValue('phonePrefix', value)
                }}
                defaultValue={countryPrefixOptions[1]}
              />

              <Input<SignupStep1SchemaType>
                name="phoneNumber"
                textLabel="Cell phone number (min 7. characters)"
                onChangeText={handleChange('phoneNumber')}
                onBlur={handleBlur('phoneNumber')}
                value={values.phoneNumber}
                autoComplete="tel"
                inputMode="tel"
                touched={touched}
                errors={errors}
              />

              <Button
                style={{ marginTop: 24, marginBottom: 80 }}
                onPress={() => {
                  handleSubmit()
                }}
                disabled={!isValid}
              >
                Next step
              </Button>

              <TextAndLink
                text="Already have an account?"
                style={{ justifyContent: 'center' }}
                linkOnPress={() => {
                  navigation.goBack()
                }}
                linkText="Log in"
              />
            </KeyboardFix>
          </>
        )}
      </Formik>
    </SafeArea>
  )
}
export default SignupStep1
