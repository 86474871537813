// External
import { useState, useRef, useCallback } from 'react'
import {
  collection,
  query,
  where,
  orderBy,
  startAfter,
  limit,
  getDocs,
  type QueryDocumentSnapshot,
  type DocumentData
} from 'firebase/firestore'
import { useFocusEffect } from '@react-navigation/native'
// Config
import { db } from '@/config/firebase'
// Models
import { type RequestWithID } from '@/models/request.interface'
// Stores
import useAppStore from '@/store/useAppStore'

const useRequests = () => {
  const [loading, setLoading] = useState(true)
  const [requests, setRequests] = useState<RequestWithID[]>([])
  const lastDoc = useRef<QueryDocumentSnapshot<DocumentData>>()
  const [hasMore, setHasMore] = useState(true)
  const user = useAppStore((state) => state.user)

  useFocusEffect(
    useCallback(() => {
      const fetchRequests = async () => {
        setLoading(true)

        if (user !== undefined) {
          const q = query(
            collection(db, 'requests'),
            where('user.email', '==', user.email),
            orderBy('dateFrom', 'desc'),
            limit(10)
          )

          try {
            const querySnapshot = await getDocs(q)

            if (!querySnapshot.empty) {
              const data = querySnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data()
              })) as RequestWithID[]
              setRequests(data)
              lastDoc.current =
                querySnapshot.docs[querySnapshot.docs.length - 1]
            } else {
              setHasMore(false)
            }
          } catch (error) {
            console.error('Error fetching requests:', error)
          }
        }

        setLoading(false)
      }

      lastDoc.current = undefined
      setHasMore(true)
      void fetchRequests()
    }, [])
  )

  const loadMore = async () => {
    if (hasMore && user !== undefined && lastDoc.current !== undefined) {
      const q = query(
        collection(db, 'requests'),
        where('user.email', '==', user.email),
        orderBy('dateFrom', 'desc'),
        limit(10),
        startAfter(lastDoc.current)
      )

      try {
        const querySnapshot = await getDocs(q)

        if (!querySnapshot.empty) {
          const data = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data()
          })) as RequestWithID[]
          setRequests((prev) => [...prev, ...data])
          lastDoc.current = querySnapshot.docs[querySnapshot.docs.length - 1]
        } else {
          setHasMore(false)
        }
      } catch (error) {
        console.error('Error fetching requests:', error)
      }
    }
  }

  return { loading, requests, loadMore }
}

export default useRequests
