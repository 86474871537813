// External
import { useState } from 'react'
import { View, StyleSheet, Dimensions } from 'react-native'
import { type StackScreenProps } from '@react-navigation/stack'
import { Formik } from 'formik'
import { object, date, string, ref, array } from 'yup'
import Toast from 'react-native-toast-message'
import { HelperText } from 'react-native-paper'
import { type CameraCapturedPicture } from 'expo-camera'
import { format } from 'date-fns'
import type { DocumentPickerAsset } from 'expo-document-picker'
import type { ImagePickerAsset } from 'expo-image-picker'
// Components
import { Button, Input, Text, DropdownSelect } from '@/components/common'
import { FileAttachment, RestrictionWarnings } from '@/components/calendar'
// Constants
import { colors } from '@/constants'
// Config
import { auth } from '@/config/firebase'
// Layouts
import { SafeArea, KeyboardFix } from '@/layouts'
import { type CalendarStackParamList } from '@/navigators/CalendarStack'
// Models
import { UseCaseError } from '@/models'
// Store
import useAppStore from '@/store/useAppStore'
// Use cases
import { submitRequest } from '@/useCases/request.use-case'
// Utils
import {
  showRequestRestrictions,
  generateValidDropdownSelectDates,
  areDatesInRange
} from '@/utils'

type Props = StackScreenProps<CalendarStackParamList, 'RequestADayOff'>

const RequestADayOff = ({ navigation, route }: Props) => {
  const user = useAppStore((state) => state.user)
  const [validSelectDropdownDates, setValidSelectDropdownDates] = useState(
    generateValidDropdownSelectDates()
  )
  const { dateFrom, dateTo } = route.params

  const validationSchema = object({
    dateFrom: date(),
    dateTo: date().min(ref('dateFrom'), 'Date to must be after date from'),
    reason: string(),
    attachments: array<
      CameraCapturedPicture | DocumentPickerAsset | ImagePickerAsset
    >()
  })

  return (
    <SafeArea
      disabledInsets={['top', 'bottom']}
      style={{
        marginBottom: 15,
        marginTop: Dimensions.get('window').height < 600 ? 20 : 40
      }}
    >
      <Formik
        initialValues={{
          dateFrom,
          dateTo,
          reason: '',
          attachments: []
        }}
        validationSchema={validationSchema}
        onSubmit={async ({ attachments, dateFrom, dateTo, reason }) => {
          try {
            if (user !== undefined && auth.currentUser !== null) {
              const verifyValidDates = generateValidDropdownSelectDates()
              const rangeStart = verifyValidDates[0].value as Date
              const rangeEnd = verifyValidDates[verifyValidDates.length - 1]
                .value as Date

              const datesInRange = areDatesInRange({
                dateFrom,
                dateTo,
                rangeStart,
                rangeEnd
              })

              if (!datesInRange) {
                setValidSelectDropdownDates(verifyValidDates)
                Toast.show({
                  type: 'error',
                  text1: 'One of the selected dates is invalid',
                  text2:
                    'Please review the restrictions and select a valid date range'
                })
                return
              }

              const res = await submitRequest({
                attachments,
                dateFrom,
                dateTo,
                reason,
                user,
                userId: auth.currentUser.uid
              })
              Toast.show({
                type: 'success',
                text1: res.title
              })
              navigation.goBack()
            }
          } catch (error) {
            if (error instanceof UseCaseError) {
              Toast.show({
                type: 'error',
                text1: error.title,
                text2: error.message
              })
            }
          }
        }}
      >
        {({
          values,
          handleSubmit,
          setFieldValue,
          handleChange,
          handleBlur,
          touched,
          errors,
          isSubmitting,
          isValid
        }) => (
          <>
            {showRequestRestrictions() && <RestrictionWarnings />}

            <KeyboardFix
              contentContainerStyle={{
                paddingHorizontal: 24
              }}
            >
              <Text variant="baseBold">Select days *</Text>

              <View
                style={[
                  styles.dropdownContainer,
                  {
                    marginVertical: 24
                  }
                ]}
              >
                <Text variant="baseBold" style={styles.sideLabel}>
                  From
                </Text>

                <DropdownSelect
                  options={validSelectDropdownDates}
                  onSelect={({ value }) => {
                    setFieldValue('dateFrom', value)
                  }}
                  defaultValue={{
                    value: dateFrom,
                    label: format(dateFrom, 'MMM do, yyyy')
                  }}
                />
              </View>

              <View style={styles.dropdownContainer}>
                <Text variant="baseBold" style={styles.sideLabel}>
                  To
                </Text>

                <DropdownSelect
                  options={validSelectDropdownDates}
                  onSelect={({ value }) => {
                    setFieldValue('dateTo', value)
                  }}
                  defaultValue={{
                    value: dateTo,
                    label: format(dateTo, 'MMM do, yyyy')
                  }}
                />
              </View>

              <HelperText type="error" visible={errors.dateTo !== undefined}>
                {errors.dateTo as string}
              </HelperText>

              <Input
                name="reason"
                textLabel="Reason of absence"
                placeholder="Ex: surgery"
                value={values.reason}
                onChangeText={handleChange('reason')}
                onBlur={handleBlur('reason')}
                autoCapitalize="sentences"
                errors={errors}
                touched={touched}
                style={styles.reason}
              />

              <FileAttachment
                attachments={values.attachments}
                onDocumentPicked={(document) => {
                  if (
                    values.attachments
                      // @ts-expect-error name always exists here
                      .map((item) => item.name)
                      .includes(document.name)
                  ) {
                    Toast.show({
                      type: 'error',
                      text1: 'You cannot add the same file twice'
                    })
                    return
                  }

                  setFieldValue('attachments', [
                    ...values.attachments,
                    document
                  ])
                }}
                onPictureTaken={(picture) => {
                  setFieldValue('attachments', [...values.attachments, picture])
                }}
                removeAttachment={(attachment) => {
                  setFieldValue(
                    'attachments',
                    values.attachments.filter(
                      // @ts-expect-error uri always exists here
                      (item) => item.uri !== attachment.uri
                    )
                  )
                }}
              />
            </KeyboardFix>

            <View>
              <Text
                style={styles.mandatoryField}
                color={colors.secondaryMediumGray}
                variant="smallRegular"
              >
                *Mandatory field
              </Text>
            </View>

            <Button
              disabled={!isValid || isSubmitting}
              loading={isSubmitting}
              style={styles.button}
              onPress={() => {
                handleSubmit()
              }}
            >
              Submit request
            </Button>
          </>
        )}
      </Formik>
    </SafeArea>
  )
}
export default RequestADayOff

const styles = StyleSheet.create({
  dropdownContainer: {
    flexDirection: 'row',
    alignItems: 'flex-end'
  },
  sideLabel: {
    width: 44,
    marginRight: 12
  },
  reason: {
    marginBottom: 8,
    marginTop: 24
  },
  mandatoryField: {
    marginHorizontal: 24
  },
  button: {
    marginTop: 8,
    marginHorizontal: 24
  }
})
