// External
import { Linking } from 'react-native'
import Toast from 'react-native-toast-message'
// Models
import { type Attachment } from '@/models'

export const openAttachment = async (attachment: Attachment) => {
  try {
    const canOpen = await Linking.canOpenURL(attachment.url as string)
    if (canOpen) {
      await Linking.openURL(attachment.url as string)
    }
  } catch (error) {
    console.error('Error trying to open attachment with RN Linking:', error)
    Toast.show({
      type: 'error',
      text1: 'Something went wrong',
      text2: 'This attachment cannot be opened'
    })
  }
}
