// External
import { useState } from 'react'
import { View } from 'react-native'
import Toast from 'react-native-toast-message'
// Components
import { Text, Button } from '@/components/common'
// Config
import { auth } from '@/config/firebase'
// Constants
import { colors } from '@/constants'
// Models
import { UseCaseError } from '@/models'
// Store
import usePortalStore from '@/store/usePortalStore'
// Use cases
import { deleteUserAccount } from '@/useCases/user.use-case'

export const DeleteAccount = () => {
  const [deleting, setDeleting] = useState(false)
  const hideModal = usePortalStore((state) => state.hideModal)

  return (
    <View>
      <Text>
        Are you sure you want to delete your account? Please keep in mind that
        this process is{' '}
        <Text variant="baseBold" color={colors.denied}>
          irreversible
        </Text>
        . Once your account is deleted, you will have no means to recover it
      </Text>

      <Button
        style={{ marginTop: 40 }}
        onPress={async () => {
          try {
            if (auth.currentUser !== null) {
              setDeleting(true)
              const res = await deleteUserAccount(auth.currentUser.uid)
              setDeleting(false)

              hideModal()
              Toast.show({
                type: 'success',
                text1: res.title,
                text2: res.message
              })
            }
          } catch (error) {
            if (error instanceof UseCaseError) {
              setDeleting(false)
              Toast.show({
                type: 'error',
                text1: error.title,
                text2: error.message
              })
            }
          }
        }}
        disabled={deleting}
        loading={deleting}
      >
        {deleting ? 'Deleting account' : 'Delete account'}
      </Button>
    </View>
  )
}
