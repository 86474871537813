// External
import { type SheetDefinition, registerSheet } from 'react-native-actions-sheet'
import type { DocumentPickerAsset } from 'expo-document-picker'
import type { CameraCapturedPicture } from 'expo-camera'
import type { ImagePickerAsset } from 'expo-image-picker'
// Components
import { AddAttachments } from './AddAttachments'
import { EditAvatar } from './EditAvatar'

registerSheet('add-attachments', AddAttachments)
registerSheet('edit-avatar', EditAvatar)

declare module 'react-native-actions-sheet' {
  interface Sheets {
    'add-attachments': SheetDefinition<{
      payload: {
        onDocumentPicked: (attachment: DocumentPickerAsset) => void
        onPictureTaken: (picture: CameraCapturedPicture) => void
      }
    }>
    'edit-avatar': SheetDefinition<{
      payload: {
        onImageSelected: (image: ImagePickerAsset) => void
        onPictureTaken: (picture: CameraCapturedPicture) => void
      }
    }>
  }
}

export {}
