// External
import { ScrollView } from 'react-native'
import { type StackScreenProps } from '@react-navigation/stack'
import { useState } from 'react'
import { Divider } from 'react-native-paper'
import { format } from 'date-fns'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
// Components
import { Link, Text } from '@/components/common'
import { CancelRequest } from '@/components/modal'
// Constants
import { colors } from '@/constants'
// Models
import { type HistoryStackParamList } from '@/navigators/HistoryStack'
// Utils
import { openAttachment } from '@/utils'
// Store
import usePortalStore from '@/store/usePortalStore'

type Props = StackScreenProps<HistoryStackParamList, 'RequestSummary'>

const RequestSummary = ({ route }: Props) => {
  const { left, right } = useSafeAreaInsets()
  const [request, setRequest] = useState(route.params.request)
  const showModal = usePortalStore((state) => state.showModal)
  const hideModal = usePortalStore((state) => state.hideModal)

  return (
    <ScrollView
      style={{ flex: 1 }}
      contentContainerStyle={{
        paddingLeft: left + 24,
        paddingRight: right + 24,
        paddingTop: 40
      }}
    >
      <Text variant="baseBold" style={{ marginBottom: 16 }}>
        Time off
      </Text>
      <Divider style={{ marginBottom: 24 }} />

      <Text variant="baseBold" style={{ marginBottom: 16 }}>
        Status:{' '}
        <Text
          variant="baseBold"
          color={colors[request.status]}
          style={{ textTransform: 'capitalize' }}
        >
          {request.status}
        </Text>
      </Text>
      <Divider style={{ marginBottom: 24 }} />

      <Text variant="baseBold" style={{ marginBottom: 16 }}>
        Date of request:{' '}
        <Text>{format(request.createdAt.toDate(), 'MMM do, yyyy')}</Text>
      </Text>
      <Divider style={{ marginBottom: 24 }} />

      <Text variant="baseBold" style={{ marginBottom: 16 }}>
        From: <Text>{format(request.dateFrom.toDate(), 'MMM do, yyyy')}</Text>
      </Text>
      <Divider style={{ marginBottom: 24 }} />

      <Text variant="baseBold" style={{ marginBottom: 16 }}>
        Until: <Text>{format(request.dateTo.toDate(), 'MMM do, yyyy')}</Text>
      </Text>
      <Divider style={{ marginBottom: 24 }} />

      <Text variant="baseBold" style={{ marginBottom: 16 }}>
        Reason of absence: <Text>{request.reason}</Text>
      </Text>
      <Divider style={{ marginBottom: 24 }} />

      {request.attachments.length === 0 ? (
        <>
          <Text variant="baseBold" style={{ marginBottom: 16 }}>
            Attached documents: —
          </Text>

          <Divider style={{ marginBottom: 24 }} />
        </>
      ) : (
        <>
          <Text variant="baseBold" style={{ marginBottom: 16 }}>
            Attached documents:
          </Text>

          <ScrollView>
            {request.attachments.map((attachment, index) => (
              <Link
                text={attachment.filename}
                key={attachment.filename}
                onPress={() => {
                  void openAttachment(attachment)
                }}
                variant="smallLinkRegular"
                numberOfLines={1}
                style={{
                  marginBottom:
                    index === request.attachments.length - 1 ? 0 : 16
                }}
              />
            ))}

            <Divider style={{ marginTop: 16, marginBottom: 24 }} />
          </ScrollView>
        </>
      )}

      {request.status === 'pending' && (
        <Link
          text="Cancel request"
          variant="link"
          style={{ marginTop: 'auto', alignSelf: 'center', marginBottom: 40 }}
          onPress={() => {
            showModal({
              content: (
                <CancelRequest
                  id={request.id}
                  onCancel={() => {
                    setRequest((prev) => ({ ...prev, status: 'cancelled' }))
                    hideModal()
                  }}
                />
              )
            })
          }}
        />
      )}
    </ScrollView>
  )
}
export default RequestSummary
