// External
import { type StackHeaderProps } from '@react-navigation/stack'
import { type BottomTabHeaderProps } from '@react-navigation/bottom-tabs'
import { View } from 'react-native'
import { IconButton } from 'react-native-paper'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
// Components
import { Text } from '@/components/common/Text'
// Constants
import { colors } from '@/constants'

export const NavigationHeader = ({
  navigation,
  options
}: StackHeaderProps | BottomTabHeaderProps) => {
  const { left, top, right } = useSafeAreaInsets()
  const title = options.headerTitle as string
  let showBackButton = false
  if (navigation.getState().index > 0 && navigation.canGoBack()) {
    showBackButton = true
  }

  return (
    <View
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: left,
        marginTop: top,
        marginRight: right
      }}
    >
      {showBackButton && (
        <IconButton
          icon="arrow-left"
          iconColor={colors.orange}
          style={{ position: 'absolute', left: 0 }}
          onPress={() => {
            navigation.goBack()
          }}
        />
      )}

      <Text variant="h2Bold">{title}</Text>
    </View>
  )
}
