import { Button as PaperButton, type ButtonProps } from 'react-native-paper'

export const BaseButton = (props: ButtonProps) => {
  const baseButtonProps: ButtonProps = {
    ...props,
    uppercase: false
  }

  return <PaperButton {...baseButtonProps} />
}
