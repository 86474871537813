// External
import { Calendar, type CalendarProps } from 'react-native-calendars'
import { StyleSheet, Platform } from 'react-native'
import { isSameDay } from 'date-fns'
// Constants
import { colors } from '@/constants'

export const CustomCalendar = ({
  style,
  disabledByDefault,
  minDate,
  maxDate,
  onDayPress,
  markingType = 'dot',
  markedDates
}: CalendarProps) => {
  return (
    <Calendar
      disabledByDefault={disabledByDefault}
      disableAllTouchEventsForDisabledDays={disabledByDefault}
      minDate={minDate}
      maxDate={maxDate}
      onDayPress={onDayPress}
      markingType={markingType}
      markedDates={markedDates}
      enableSwipeMonths
      hideExtraDays
      style={[styles.container, style]}
      theme={{
        ...(Platform.OS === 'web' && {
          arrowWidth: 20,
          arrowHeight: 20
        }),
        dotColor: colors.orange,
        selectedDayBackgroundColor: colors.orange,
        arrowColor: colors.orange,
        textDayFontFamily: 'Maersk-Regular',
        textDayFontSize: 14,
        textDayHeaderFontFamily: 'Maersk-Bold',
        textDayHeaderFontSize: 14,
        textMonthFontSize: 16,
        textMonthFontFamily: 'Maersk-Bold',
        dayTextColor: colors.primaryDarkGray,
        monthTextColor: colors.primaryDarkGray,
        todayTextColor: isSameDay(new Date(), new Date(minDate as string))
          ? colors.primaryDarkGray
          : '#d9e1e8',
        // @ts-expect-error - TS complains that stylesheet.calendar.header doesn't exist in the theme, ignore it. The following line injects extra styles for the day headers since, for example,
        // the color is not changeable. Only works with the patch-package patch
        'stylesheet.calendar.header': {
          extraDayHeader: styles.extraDayHeader
        }
      }}
    />
  )
}

const styles = StyleSheet.create({
  container: {
    borderWidth: 1,
    borderColor: colors.lightGray,
    paddingVertical: 12
  },
  extraDayHeader: {
    color: colors.primaryDarkGray
  }
})
