import { type ReactNode } from 'react'
// React Native
import { View, type ViewStyle } from 'react-native'
// Safe Area
import { useSafeAreaInsets } from 'react-native-safe-area-context'

interface Props {
  children: ReactNode
  disabledInsets?: Array<'top' | 'left' | 'bottom' | 'right'>
  style?: ViewStyle
}

export const SafeArea = ({ children, disabledInsets, style }: Props) => {
  const { top, left, bottom, right } = useSafeAreaInsets()
  let paddingTop = top
  let paddingBottom = bottom

  if (disabledInsets !== undefined && disabledInsets.length > 0) {
    if (disabledInsets.includes('top')) {
      paddingTop = 0
    }

    if (disabledInsets.includes('bottom')) {
      paddingBottom = 0
    }
  }

  return (
    <View
      style={[
        {
          flex: 1,
          paddingTop,
          paddingLeft: left,
          paddingBottom,
          paddingRight: right
        },
        style
      ]}
    >
      {children}
    </View>
  )
}
