// External
import { useEffect } from 'react'
import { Platform, AppState } from 'react-native'
import Constants from 'expo-constants'
import * as Sentry from '@sentry/react-native'
// Components
import { UpdateAppVersion } from '@/components/common'
// Services
import { getAppVersion } from '@/services/app.service'
// Stores
import usePortalStore from '@/store/usePortalStore'

const useCheckAppVersion = () => {
  const showModal = usePortalStore((state) => state.showModal)

  const fetch = async () => {
    const { OS } = Platform
    if (OS === 'android') {
      try {
        const { min, latest } = await getAppVersion()

        let build =
          OS === 'android'
            ? Constants.expoConfig?.android?.versionCode
            : Constants.expoConfig?.ios?.buildNumber

        if (build !== undefined) {
          if (typeof build === 'string') {
            build = parseInt(build)
          }

          if (build < min || build < latest) {
            showModal({
              content: <UpdateAppVersion isBelowMin={build < min} />,
              dismissable: !(build < min),
              hideCloseButton: true
            })
          }
        }
      } catch (error) {
        Sentry.captureException(error)
      }
    }
  }

  useEffect(() => {
    void fetch()

    const subscription = AppState.addEventListener('change', (nextAppState) => {
      if (nextAppState === 'active') {
        void fetch()
      }
    })

    return () => {
      subscription.remove()
    }
  }, [])
}

export default useCheckAppVersion
