// External
import { type ReactNode } from 'react'
import { TouchableOpacity, type ViewStyle } from 'react-native'
// Components
import { Text } from './Text'
// Constants
import { colors } from '@/constants'
// Models
import { type OnPress } from '@/models'

interface LinkProps {
  text: ReactNode
  variant?: 'smallLinkRegular' | 'smallLinkBold' | 'link'
  color?: string
  onPress?: OnPress
  style?: ViewStyle
  numberOfLines?: number
  disabled?: boolean
}

export const Link = ({
  variant = 'smallLinkBold',
  color,
  text,
  onPress,
  style,
  numberOfLines,
  disabled
}: LinkProps) => {
  const handlePress: OnPress = (e) => {
    if (onPress !== undefined) {
      onPress(e)
    }
  }

  return (
    <TouchableOpacity onPress={handlePress} style={style} disabled={disabled}>
      <Text
        variant={variant}
        color={color !== undefined ? color : colors.orange}
        numberOfLines={numberOfLines}
      >
        {text}
      </Text>
    </TouchableOpacity>
  )
}
