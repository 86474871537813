// External
import { View } from 'react-native'
import { IconButton } from 'react-native-paper'
import ActionSheet, {
  SheetManager,
  type SheetProps
} from 'react-native-actions-sheet'
import * as DocumentPicker from 'expo-document-picker'
// Components
import { Text } from '@/components/common'
// Constants
import { colors } from '@/constants'
// Store
import usePortalStore from '@/store/usePortalStore'

export const AddAttachments = ({
  sheetId,
  payload
}: SheetProps<'add-attachments'>) => {
  const mountCameraView = usePortalStore((state) => state.mountCameraView)

  return (
    <ActionSheet id={sheetId}>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-around',
          paddingVertical: 16
        }}
      >
        <View style={{ alignItems: 'center' }}>
          <IconButton
            icon="file-plus-outline"
            iconColor={colors.orange}
            size={40}
            onPress={async () => {
              try {
                const attachment = await DocumentPicker.getDocumentAsync()
                if (!attachment.canceled) {
                  await SheetManager.hide('add-attachments')
                  payload?.onDocumentPicked(attachment.assets[0])
                }
              } catch (error) {
                console.error(error)
                void SheetManager.hide('add-attachments')
              }
            }}
          />

          <Text variant="baseBold">Select file</Text>
        </View>

        <View style={{ alignItems: 'center' }}>
          <IconButton
            icon="camera-plus-outline"
            iconColor={colors.orange}
            size={40}
            onPress={() => {
              mountCameraView((picture) => payload?.onPictureTaken(picture))
              void SheetManager.hide('add-attachments')
            }}
          />

          <Text variant="baseBold">Take a photo</Text>
        </View>
      </View>
    </ActionSheet>
  )
}
