// External
import { createStackNavigator } from '@react-navigation/stack'
// Components
import { NavigationHeader } from '@/components/navigation'
// Views
import History from '@/views/Home/History/History'
import RequestSummary from '@/views/Home/History/RequestSummary'
// Models
import { type RequestWithID } from '@/models/request.interface'

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type HistoryStackParamList = {
  History: { id: string | undefined }
  RequestSummary: { request: RequestWithID }
}

const Stack = createStackNavigator<HistoryStackParamList>()

const HistoryStack = () => {
  return (
    <Stack.Navigator
      initialRouteName="History"
      screenOptions={{ header: NavigationHeader }}
    >
      <Stack.Screen
        name="History"
        component={History}
        options={{ headerTitle: 'HISTORY' }}
      />

      <Stack.Screen
        name="RequestSummary"
        component={RequestSummary}
        options={{ headerTitle: 'REQUEST SUMMARY' }}
      />
    </Stack.Navigator>
  )
}
export default HistoryStack
