// External
import { SheetManager } from 'react-native-actions-sheet'
import { Divider, IconButton } from 'react-native-paper'
import { View } from 'react-native'
import type { CameraCapturedPicture } from 'expo-camera'
import type { DocumentPickerAsset } from 'expo-document-picker'
import type { ImagePickerAsset } from 'expo-image-picker'
// Components
import { Text, Link, TextAndLink } from '@/components/common'
// Constants
import { colors } from '@/constants'
// Utils
import { getFilename } from '@/utils'

interface Props {
  attachments: Array<
    DocumentPickerAsset | ImagePickerAsset | CameraCapturedPicture
  >
  onDocumentPicked: (attachment: DocumentPickerAsset) => void
  onPictureTaken: (picture: CameraCapturedPicture) => void
  removeAttachment: (
    attachment: DocumentPickerAsset | ImagePickerAsset | CameraCapturedPicture
  ) => void
}

export const FileAttachment = ({
  attachments,
  onDocumentPicked,
  onPictureTaken,
  removeAttachment
}: Props) => {
  const openAddAttachments = () => {
    void SheetManager.show('add-attachments', {
      payload: {
        onDocumentPicked,
        onPictureTaken
      }
    })
  }

  return (
    <>
      <Text variant="baseBold" style={{ marginBottom: 16 }}>
        File attachment
      </Text>

      {attachments.length === 0 ? (
        <TextAndLink
          style={{ marginBottom: 24 }}
          text="You haven't attached any files yet."
          linkText="Attach files"
          linkOnPress={openAddAttachments}
        />
      ) : (
        <View style={{ marginBottom: 80 }}>
          {attachments.map((attachment, index) => (
            <View
              style={{ flexDirection: 'row', alignItems: 'center' }}
              key={index}
            >
              <Link
                text={getFilename(attachment)}
                variant="smallLinkRegular"
                style={{
                  flex: 1
                }}
                numberOfLines={1}
              />

              <IconButton
                icon="trash-can-outline"
                onPress={() => {
                  removeAttachment(attachment)
                }}
                iconColor={colors.orange}
                size={16}
              />
            </View>
          ))}

          <Link text="Add more files" onPress={openAddAttachments} />

          <Divider style={{ marginTop: 16 }} />
        </View>
      )}
    </>
  )
}
