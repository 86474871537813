// External
import { useState, useCallback } from 'react'
import Toast from 'react-native-toast-message'
import { useFocusEffect } from '@react-navigation/native'
// Config
import { auth } from '@/config/firebase'
// Models
import { type NotificationWithID } from '@/models/notification.interface'
import { UseCaseError } from '@/models/use-case-error.class'
import { type FetchStatus } from '@/models/fetch-status.type'
// Use cases
import { listNotifications } from '@/useCases/notification.use-case'

const useNotifications = () => {
  const [status, setStatus] = useState<FetchStatus>('loading')
  const [notifications, setNotifications] = useState<NotificationWithID[]>([])

  const fetch = async () => {
    try {
      // @ts-expect-error - TS complains that currentUser may be null, but the screen this hook is called on is only mounted when the user is logged in
      const res = await listNotifications(auth.currentUser.uid)
      setNotifications(res.data)
      setStatus('idle')
    } catch (error) {
      if (error instanceof UseCaseError) {
        Toast.show({
          type: 'error',
          text1: error.title,
          text2: error.message
        })
        setStatus('idle')
      }
    }
  }

  const refresh = () => {
    setStatus('refreshing')
    void fetch()
  }

  useFocusEffect(
    useCallback(() => {
      setStatus('loading')
      void fetch()
    }, [])
  )

  return { status, notifications, refresh }
}
export default useNotifications
