// External
import { View } from 'react-native'
import { IconButton } from 'react-native-paper'
import ActionSheet, {
  SheetManager,
  type SheetProps
} from 'react-native-actions-sheet'
import * as ImagePicker from 'expo-image-picker'
// Components
import { Text } from '@/components/common'
// Constants
import { colors } from '@/constants'
// Store
import usePortalStore from '@/store/usePortalStore'

export const EditAvatar = ({ sheetId, payload }: SheetProps<'edit-avatar'>) => {
  const mountCameraView = usePortalStore((state) => state.mountCameraView)

  return (
    <ActionSheet id={sheetId}>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-around',
          paddingVertical: 16
        }}
      >
        <View style={{ alignItems: 'center' }}>
          <IconButton
            icon="file-image-plus-outline"
            iconColor={colors.orange}
            size={40}
            onPress={async () => {
              const res = await ImagePicker.launchImageLibraryAsync({
                mediaTypes: ImagePicker.MediaTypeOptions.Images
              })

              if (res.canceled) {
                return
              }

              await SheetManager.hide('edit-avatar')
              payload?.onImageSelected(res.assets[0])
            }}
          />

          <Text variant="baseBold">Select a photo</Text>
        </View>

        <View style={{ alignItems: 'center' }}>
          <IconButton
            icon="camera-plus-outline"
            iconColor={colors.orange}
            size={40}
            onPress={() => {
              mountCameraView((picture) => payload?.onPictureTaken(picture))
              void SheetManager.hide('edit-avatar')
            }}
          />

          <Text variant="baseBold">Take a photo</Text>
        </View>
      </View>
    </ActionSheet>
  )
}
