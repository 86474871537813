// External
import { View } from 'react-native'
import * as Linking from 'expo-linking'
// Components
import { Button } from './Button'
import { Text } from './Text'
// Stores
import usePortalStore from '@/store/usePortalStore'

const storeUpdateLink =
  'https://play.google.com/store/apps/details?id=com.estudiocactus.apmlaboravailability'

interface Props {
  isBelowMin: boolean
}

export const UpdateAppVersion = ({ isBelowMin }: Props) => {
  const showModal = usePortalStore((state) => state.showModal)

  const openStoreUpdateLink = async () => {
    try {
      if (await Linking.canOpenURL(storeUpdateLink)) {
        await Linking.openURL(storeUpdateLink)
      } else {
        throw new Error()
      }
    } catch (error) {
      console.error('Error opening store update link:', error)
      showModal({
        content: <Text>Couldn't open the Play Store</Text>
        // dismissable: !isBelowMin
      })
    }
  }

  return (
    <View>
      <Text style={{ marginBottom: 34 }}>
        {isBelowMin
          ? 'Your version of APM Labor Availability is no longer supported, to continue using the app, please update to the latest version'
          : 'A new version of APM Labor Availability has been released, please update'}
      </Text>

      <Button onPress={openStoreUpdateLink}>Update App</Button>
    </View>
  )
}
