// Components
import { Link } from './Link'
import { Text } from './Text'
// External
import { View, type StyleProp, type ViewStyle } from 'react-native'
import { Children } from 'react'
// Models
import { type OnPress } from '@/models'

interface TextAndLinkProps {
  style?: StyleProp<ViewStyle>
  text: string
  linkText: string | string[]
  linkOnPress: OnPress | OnPress[]
}

export const TextAndLink = ({
  style,
  text,
  linkOnPress,
  linkText
}: TextAndLinkProps) => {
  const getLinks = () => {
    if (typeof linkText === 'string' && typeof linkOnPress === 'function') {
      return <Link onPress={linkOnPress} text={linkText} />
    }

    if (Array.isArray(linkText) && Array.isArray(linkOnPress)) {
      return Children.toArray(
        linkText.map((text, index) => (
          <>
            {index !== 0 ? <Text>, </Text> : undefined}
            <Link onPress={linkOnPress[index]} text={text} />
          </>
        ))
      )
    }
  }

  return (
    <View
      style={[
        {
          flexDirection: 'row',
          flexWrap: 'wrap',
          alignItems: 'baseline'
        },
        style
      ]}
    >
      <Text>
        {text}
        {'  '}
      </Text>

      {getLinks()}
    </View>
  )
}
